import React from "react";

export default function Instruction({ nextStep, prevStep }) {
  const handleNext = () => {
    // Add your next step logic here
    nextStep();
  };

  const handlePrevious = () => {
    // Add your previous step logic here
    prevStep();
  };

  return (
    <div className="mt-8 rounded-lg text-center mx-auto" style={{ position: "relative" }}>
      <div>
        <h1 className="py-5 text-center font-bold text-2xl sm:text-4xl">
        Application for Award of Endowment Fund Scholarship for <p>
            Academic Session 2023-24
            </p> 
        </h1>
        <hr className="border-t-2 border-gray-700 my-5 mx-2 rounded-none" />
      </div>
      <div className="mx-3 sm:mx-6">
        <h1 className="font-semibold sm:text-2xl uppercase sm:font-bold text-xl">
          Please read all the Instructions carefully
        </h1>
        <h1 className="mt-6 text-justify">
          1. Students already availing SEEF Scholarship Need "NOT" to Apply again.
          <p>2. Over-sea or Self-Financed Students are not eligible for SEEF Trust Scholarship.</p>
          {/* <p>3. First Year Students are eligible for SEEF Trust Scholarship.</p> */}
          <p>3. Filling of All field is mandatory (mention "N/A" where not applicable).</p>
          <p>4. Please Submit the Hard Copy of this Form in concern department of your university.</p>
        </h1>
        <hr className="border-t-2 border-gray-700 my-5 rounded-none w-9/12" />
      </div>
      <div className="flex justify-between mx-3 sm:mx-6">
        
        <button
          type="button"
          onClick={handleNext}
          className="px-6 py-2 bg-blue-600 text-white rounded-md"
        >
          Next
        </button>
      </div>
    </div>
  );
}
