import React from "react";

const Header = () => {
  return (
    <header className="flex justify-between text-center mx-auto">
      <div className="flex items-center">
        <img
          className="w-20"
          src="/logo.png"
          alt="Logo"
        />
      </div>
      <div className="font-bold">
        <h2 className="md:text-lg lg:text-lg">
          GOVERNMENT OF SINDH
          <p>COLLEGE EDUCATION DEPARTMENT</p>
          <p>SINDH EDUCATIONAL ENDOWMENT FUND</p>
          <p>REGISTERED VIDE TRUST DEED NO. 625/2020</p>
        </h2>
      </div>
    </header>
  );
};

export default Header;
