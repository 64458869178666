import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import MultiStepForm from "./components/Form/Form";
import MainForm from "./components/pages/MainForm/MainForm";
import Admin from "./components/Admin/Admin";
import Login from './components/pages/Login';
import QRCODE from "./components/QRCode/qrcode";
import VerifyExistenceSwal from "./components/pdfform/Swal";
import Landing from "./components/pages/Landing";
import Layout from "./components/Layout/Layout"; // Import the layout component
import Universities from "./components/Universities/Universities";

const App = () => {
  const [formData, setFormData] = useState({});

  return (
    <>
      <div>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
            <Route path="/form" element={<MultiStepForm formData={formData} setFormData={setFormData} />} />
            <Route path="/MainForm" element={<MainForm />} />
            <Route path="/QRCODE" element={<QRCODE />} />
            <Route path="/VERIFICATION" element={<VerifyExistenceSwal />} />
          <Route path="/login" element={<Login />} /> {/* Login doesn't use the Layout */}
          <Route path="/universities" element={<Universities />} /> {/* Login doesn't use the Layout */}
          </Route>
            <Route path="/Admin" element={<Admin />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
