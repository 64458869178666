import React, { useState, useEffect } from "react";
import axios from "axios";
import SkeletonLoader from "react-loading-skeleton"; // Import loading skeleton library
import AuthUser from "../Loji/loji";

const UniversityTable = () => {
  const [universitiesData, setUniversitiesData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
const {http} = AuthUser();
const SEEF_UNIVERSITIES = 'universities'
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get(SEEF_UNIVERSITIES);
        setUniversitiesData(response.data);
        setLoading(false); // Update loading state after data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Update loading state in case of error
      }
    };

    fetchData();
  }, []);

  // Filter universities based on search term
  const filteredUniversities = universitiesData.filter((university) =>
    university.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle change in search input
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="container h-screen mx-auto md:py-6">
      <div className="max-w-7xl mx-auto p-2 lg:px-8">
        <h2 className="text-2xl underline mt-4 font-bold">
          Universities under SEEF Scholarship Program
        </h2>
        <div className="mt-4 mb-2 flex justify-end items-center sm:mb-4">
          <input
            type="text"
            placeholder="Search by name..."
            className="border border-gray-300 rounded-md px-3 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={handleChange}
          />
        </div>
        <div className="border-2 border-gray-200 shadow-xl rounded-lg overflow-x-auto overflow-y-auto" style={{ maxHeight: "700px" }}>
          {loading ? ( // Conditional rendering based on loading state
            <SkeletonLoader count={5} height={50} /> // Example skeleton loader
          ) : (
            <table className="min-w-full divide-y  divide-gray-200 bg-white">
              <thead className="bg-gray-50 sticky top-0">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {filteredUniversities.map((university, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {university}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default UniversityTable;
