import React from 'react';
import Dashboard from '../AdminComponents/Dashboard/Dashboard'; // Adjust the import path according to your project structure
import UniversityTable from '../../Universities/Universities';

const MainContent = ({ selectedMenu, isOpen }) => {
  const renderContent = () => {
    switch (selectedMenu) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Users':
        return <UsersContent />;
      case 'Settings':
        return <SettingsContent />;
      case 'Universities':
        return <UniversityTable />;
      case 'Districts':
        return <DistrictsContent />;
      case 'Discipline':
        return <DisciplineContent />;
      case 'Logout':
        return <LogoutContent />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className={`flex-1 px-4  border-2 ml-12 ${isOpen ?  'ml-56' : 'ml:12'}`}>
      {renderContent()}
    </div>
  );
};

const UsersContent = () => (
  <div>
    <h2>Users Content</h2>
    {/* Add your users content here */}
  </div>
);

const SettingsContent = () => (
  <div>
    <h2>Settings Content</h2>
    {/* Add your settings content here */}
  </div>
);

const UniversitiesContent = () => (
  <div>
    <h2>Universities Content</h2>
    {/* Add your universities content here */}
  </div>
);

const DistrictsContent = () => (
  <div>
    <h2>Districts Content</h2>
    {/* Add your districts content here */}
  </div>
);

const DisciplineContent = () => (
  <div>
    <h2>Discipline Content</h2>
    {/* Add your discipline content here */}
  </div>
);

const LogoutContent = () => (
  <div>
    <h2>Logging out...</h2>
    {/* Add your logout logic here */}
  </div>
);

export default MainContent;
