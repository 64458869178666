import React, { useState, useRef } from 'react';
import QRCode from 'qrcode.react';
import { jsPDF } from 'jspdf';
import pdfform from '../Form/Pdfform'
import html2canvas from 'html2canvas';
import Header from '../Header/Header';
import MainForm from '../pages/MainForm/MainForm';

const QrCodeGeneratorAndScanner = ({ formData }) => {
  const [qrValue, setQrValue] = useState(''); // State to hold QR code value
  const [scanned, setScanned] = useState(false); // State to track if QR code is scanned
  const qrRef = useRef(); // Ref to hold QR code element

  const websiteUrl = 'http://localhost:3000/MainForm'; // URL to encode in the QR code
  const personal_id = formData?.cnicStudent;

 

  const handleError = (err) => {
    console.error(err);
  };

  const generatePDF = async () => {
    const qrElement = qrRef.current.querySelector('canvas'); // Select the canvas element of QR code
    const canvas = await html2canvas(qrElement); // Convert QR code canvas to a new canvas
    const imgData = canvas.toDataURL('image/png'); // Get image data URL from canvas
    const pdf = new jsPDF(); // Create new jsPDF instance
    pdf.addImage(imgData, 'PNG', 10, 10, 50, 50); // Add image to PDF
    // pdf.save('QRCode.pdf'); // Save PDF
  };

  return (
    <div className="flex flex-col items-center p-4">
      {!scanned ? (
        <>
          {/* Display the predefined QR code */}
          <div className="mb-4" ref={qrRef}>
            <QRCode value={websiteUrl} size={256} />
          </div>

          {/* Button to generate PDF */}
          {/* <button onClick={generatePDF} className="mb-4 px-4 py-2 bg-blue-500 text-white rounded">
            Generate PDF
          </button> */}

          {/* QR code reader component */}
          {/* <div className="w-full max-w-md">
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: '100%' }}
            />
          </div> */}
        </>
      ) : (
        <div className="min-h-screen flex flex-col items-center p-4 bg-gray-100">
          <Header />
          <MainForm userInfo={{ websiteUrl, personal_id }} />
        </div>
      )}
    </div>
  );
};

export default QrCodeGeneratorAndScanner;
