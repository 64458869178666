import React, { useState, useEffect } from "react";
import AuthUser from "../Loji/loji"; // Adjust the path based on your actual file structure

const PersonalInformation = ({ formData, setFormData, nextStep, prevStep }) => {
  const [errors, setErrors] = useState({});
  const [photoPreview, setPhotoPreview] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [districts, setDistricts] = useState([]);
  const { http } = AuthUser();
  const SEEF_PERSONALS = "personals";
  const SEEF_DISTRICTS = "districts";

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await http.get(SEEF_DISTRICTS);
        setDistricts(response.data);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };

    fetchDistricts();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/svg+xml",
        "image/png",
        "image/gif",
        "image/jpg",
      ];
      if (!allowedTypes.includes(file.type)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Only JPG, SVG, GIF, PNG and JPEG files are allowed",
        }));
        setPhotoPreview(null);
        return;
      }

      if (file.size > 600 * 1024) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size must be less than 600kb",
        }));
        setPhotoPreview(null);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoPreview(reader.result);
      };
      reader.readAsDataURL(file);

      setFormData({ ...formData, [name]: file });
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleCnicStudentChange = (e) => {
    let formattedCnicStudent = e.target.value.replace(/\D/g, "");
    formattedCnicStudent = formattedCnicStudent.slice(0, 13);

    if (formattedCnicStudent.length > 5 && formattedCnicStudent.length < 13) {
      formattedCnicStudent = formattedCnicStudent.replace(
        /^(\d{5})(\d{7})?/,
        "$1-$2"
      );
    } else if (formattedCnicStudent.length === 13) {
      formattedCnicStudent = formattedCnicStudent.replace(
        /^(\d{5})(\d{7})(\d{1})/,
        "$1-$2-$3"
      );
    }

    setFormData({
      ...formData,
      cnicStudent: formattedCnicStudent,
    });

    if (errors.cnicStudent) {
      setErrors((prevErrors) => ({ ...prevErrors, cnicStudent: "" }));
    }
  };

  const handleMobileStudentChange = (e) => {
    let formattedMobileStudent = e.target.value.replace(/\D/g, "");
    formattedMobileStudent = formattedMobileStudent.slice(0, 11);

    if (formattedMobileStudent.length > 4) {
      formattedMobileStudent = formattedMobileStudent.replace(
        /^(\d{4})(\d{7})?/,
        "$1-$2"
      );
    }

    setFormData({
      ...formData,
      mobileStudent: formattedMobileStudent,
    });

    if (errors.mobileStudent) {
      setErrors((prevErrors) => ({ ...prevErrors, mobileStudent: "" }));
    }
  };

  const handleContactNumberChange = (e) => {
    let formattedContactNumber = e.target.value.replace(/\D/g, "");
    formattedContactNumber = formattedContactNumber.slice(0, 11);

    if (/^03\d{2}/.test(formattedContactNumber)) {
      formattedContactNumber = formattedContactNumber.replace(
        /^(\d{4})(\d{7})?/,
        "$1-$2"
      );
    } else {
      formattedContactNumber = formattedContactNumber.replace(
        /^(\d{3})(\d{8})?/,
        "$1-$2"
      );
    }

    setFormData({
      ...formData,
      contactNumber: formattedContactNumber,
    });

    if (errors.contactNumber) {
      setErrors((prevErrors) => ({ ...prevErrors, contactNumber: "" }));
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.gender) newErrors.gender = "Gender is required";
    if (!formData.cnicStudent) newErrors.cnicStudent = "CNIC is required";
    else if (!/^\d{5}-\d{7}-\d{1}$/.test(formData.cnicStudent))
      newErrors.cnicStudent = "CNIC must be in the format 42201-1234567-1";
    if (!formData.mobileStudent)
      newErrors.mobileStudent = "Mobile number is required";
    else if (!/^03\d{2}-\d{7}$/.test(formData.mobileStudent))
      newErrors.mobileStudent =
        "Mobile number must be in the format 0300-1234567";
    if (!formData.district)
      newErrors.district = "District of domicile is required";

    if (!formData.contactNumber)
      newErrors.contactNumber = "Contact number is required";
    else if (!/^((03\d{2}-\d{7})|(\d{3}-\d{8}))$/.test(formData.contactNumber))
      newErrors.contactNumber =
        "Contact number must be in the format 0300-1234567 or 022-12345678";
    if (!formData.photo) newErrors.photo = "Photo is required";
    if (!formData.fatherName) newErrors.fatherName = "Father's name is required";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    
    setSubmitting(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("email", formData.email);
      formDataToSend.append("cnicStudent", formData.cnicStudent);
      formDataToSend.append("firstName", formData.firstName);
      formDataToSend.append("lastName", formData.lastName);
      formDataToSend.append("gender", formData.gender);
      formDataToSend.append("district", formData.district);
      formDataToSend.append("fatherName", formData.fatherName);
      formDataToSend.append("mobileStudent", formData.mobileStudent);
      formDataToSend.append("contactNumber", formData.contactNumber);
      if (formData.photo) {
        formDataToSend.append("photo", formData.photo);
      }

      // Example of submitting data (uncomment to use)
      // const response = await http.post(
      //   SEEF_PERSONALS,
      //   formDataToSend,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      // console.log("Form data submitted successfully:", response.data);

      nextStep();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="text-2xl font-bold mb-6">Personal Information</h2>
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          First Name
        </label>
        <input
          type="text"
          name="firstName"
          placeholder="Enter your first name"
          value={formData.firstName || ""}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        {errors.firstName && (
          <p className="text-red-500 text-sm">{errors.firstName}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          Last Name
        </label>
        <input
          type="text"
          name="lastName"
          placeholder="Enter your last name"
          value={formData.lastName || ""}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        {errors.lastName && (
          <p className="text-red-500 text-sm">{errors.lastName}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          Gender
        </label>
        <select
          name="gender"
          value={formData.gender || ""}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md"
        >
          <option value="">Select gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        {errors.gender && (
          <p className="text-red-500 text-sm">{errors.gender}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          CNIC Student
        </label>
        <input
          type="text"
          name="cnicStudent"
          placeholder="42201-1234567-1"
          value={formData.cnicStudent || ""}
          onChange={handleCnicStudentChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        {errors.cnicStudent && (
          <p className="text-red-500 text-sm">{errors.cnicStudent}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          Mobile Number 
        </label>
        <input
          type="text"
          name="mobileStudent"
          placeholder="0300-1234567"
          value={formData.mobileStudent || ""}
          onChange={handleMobileStudentChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        {errors.mobileStudent && (
          <p className="text-red-500 text-sm">{errors.mobileStudent}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          Father's / Guardian's Name
        </label>
        <input
          type="text"
          name="fatherName"
          placeholder="Enter your father's name"
          value={formData.fatherName || ""}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        {errors.fatherName && (
          <p className="text-red-500 text-sm">{errors.fatherName}</p>
        )}
      </div>
     
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          Contact Number (Home)
        </label>
        <input
          type="text"
          name="contactNumber"
          placeholder="021-12345678 OR 0300-1234567 format Only"
          value={formData.contactNumber || ""}
          onChange={handleContactNumberChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        {errors.contactNumber && (
          <p className="text-red-500 text-sm">{errors.contactNumber}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          Email Address
        </label>
        <input
          type="email"
          name="email"
          placeholder="Enter your email"
          value={formData.email || ""}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          District of Domicile
        </label>
        <select
          name="district"
          value={formData.district || ""}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md"
        >
          <option value="">Select district</option>
          {districts.map((district) => (
            <option key={district} value={district}>
              {district}
            </option>
          ))}
        </select>
        {errors.district && (
          <p className="text-red-500 text-sm">{errors.district}</p>
        )}
      </div>
     
      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          Upload Passport Size Photo
        </label>
        <input
          type="file"
          name="photo"
          accept="image/*"
          onChange={handleFileChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        {photoPreview && (
          <img src={photoPreview} alt="Preview" className="mt-2 h-28 w-24" />
        )}
        {errors.photo && <p className="text-red-500 text-sm">{errors.photo}</p>}
      </div>
      <div className="flex justify-between">
        <button
          type="button"
          onClick={prevStep}
          className="px-6 py-2 bg-gray-600 text-white rounded-md"
        >
          Back
        </button>
        <button
          type="submit"
          className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          disabled={submitting}
        >
          {submitting ? "Please Wait..." : "Next"}
        </button>
      </div>
    </form>
  );
};

export default PersonalInformation;
