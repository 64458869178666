import React, { useState, useEffect } from "react";
import AuthUser from "../Loji/loji"; // Adjust the path based on your actual file structure

const FinancialInformation = ({ formData, setFormData, nextStep, prevStep }) => {
  const [totalIncome, setTotalIncome] = useState(0);
  const [fatherProfession, setFatherProfession] = useState("");
  const [fatherSource, setFatherSource] = useState("");
  const [fatherIncome, setFatherIncome] = useState("0");
  const [motherProfession, setMotherProfession] = useState("");
  const [motherSource, setMotherSource] = useState("");
  const [motherIncome, setMotherIncome] = useState("0");
  const [brotherProfession, setBrotherProfession] = useState("");
  const [brotherSource, setBrotherSource] = useState("");
  const [brotherIncome, setBrotherIncome] = useState("0");
  const [sisterProfession, setSisterProfession] = useState("");
  const [sisterSource, setSisterSource] = useState("");
  const [sisterIncome, setSisterIncome] = useState("0");
  const [guardianProfession, setGuardianProfession] = useState("");
  const [guardianSource, setGuardianSource] = useState("");
  const [guardianIncome, setGuardianIncome] = useState("0");
  const [submitting, setSubmitting] = useState(false); // State for button disabling
  const { http } = AuthUser();
  const SEEF_PERSONALS = "financial";

  useEffect(() => {
    // Calculate total income whenever any income field changes
    calculateTotalIncome();
  }, [
    fatherIncome,
    motherIncome,
    brotherIncome,
    sisterIncome,
    guardianIncome,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Regular expression to allow only numeric values
    const numericRegex = /^[0-9]*$/;

    // Validate if the value is numeric before setting state
    if (name.endsWith("Income") && !numericRegex.test(value)) {
      // If the input is not numeric, do not update the state
      return;
    }

    // Set state based on the field name
    switch (name) {
      case "fatherProfession":
        setFatherProfession(value);
        break;
      case "fatherSource":
        setFatherSource(value);
        break;
      case "fatherIncome":
        setFatherIncome(value || "0");
        break;
      case "motherProfession":
        setMotherProfession(value);
        break;
      case "motherSource":
        setMotherSource(value);
        break;
      case "motherIncome":
        setMotherIncome(value || "0");
        break;
      case "brotherProfession":
        setBrotherProfession(value);
        break;
      case "brotherSource":
        setBrotherSource(value);
        break;
      case "brotherIncome":
        setBrotherIncome(value || "0");
        break;
      case "sisterProfession":
        setSisterProfession(value);
        break;
      case "sisterSource":
        setSisterSource(value);
        break;
      case "sisterIncome":
        setSisterIncome(value || "0");
        break;
      case "guardianProfession":
        setGuardianProfession(value);
        break;
      case "guardianSource":
        setGuardianSource(value);
        break;
      case "guardianIncome":
        setGuardianIncome(value || "0");
        break;
      default:
        break;
    }
  };

  const calculateTotalIncome = () => {
    const total =
      parseInt(fatherIncome || 0) +
      parseInt(motherIncome || 0) +
      parseInt(brotherIncome || 0) +
      parseInt(sisterIncome || 0) +
      parseInt(guardianIncome || 0);
    setTotalIncome(total);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Disable submit button and show "Please wait" message
    setSubmitting(true);

    const updatedFormData = {
      fatherProfession: fatherProfession || "Not Applicable",
      fatherSource: fatherSource || "Not Applicable",
      fatherIncome,
      motherProfession: motherProfession || "Not Applicable",
      motherSource: motherSource || "Not Applicable",
      motherIncome,
      brotherProfession: brotherProfession || "Not Applicable",
      brotherSource: brotherSource || "Not Applicable",
      brotherIncome,
      sisterProfession: sisterProfession || "Not Applicable",
      sisterSource: sisterSource || "Not Applicable",
      sisterIncome,
      guardianProfession: guardianProfession || "Not Applicable",
      guardianSource: guardianSource || "Not Applicable",
      guardianIncome,
      totalIncome,
      // personal_id: formData.personal.cnicStudent // Assuming cnicStudent is the field name in formData
    };

    // Simulate successful submission and proceed to the next step
    setTimeout(() => {
      setSubmitting(false);
      setFormData({
        ...formData,
        ...updatedFormData,
      });
      nextStep();
    }, 1000);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="text-2xl font-bold mb-6">Financial Information</h2>
      <div className="text-xl font-semibold mb-5 text-center underline">
        Father's Income Detail
      </div>
      <div className="mb-6 max-w-screen-sm mx-auto">
        <div className="mb-4">
          <label className="block text-gray-700">Profession</label>
          <input
            type="text"
            name="fatherProfession"
            value={fatherProfession === "Not Applicable" ? "" : fatherProfession}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Source of Income (Salary / Pension / Others)
          </label>
          <input
            type="text"
            name="fatherSource"
            value={fatherSource === "Not Applicable" ? "" : fatherSource}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Annual Income (Yearly Income)</label>
          <input
            type="text"
            name="fatherIncome"
            value={fatherIncome}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
            pattern="[0-9]*" // Only accept numeric input
            title="Please enter numbers only"
          />
        </div>
        <div className="text-xl font-semibold mb-5 text-center underline">
          Mother's Income Detail
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Profession</label>
          <input
            type="text"
            name="motherProfession"
            value={motherProfession === "Not Applicable" ? "" : motherProfession}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Source of Income (Salary / Pension / Others)
          </label>
          <input
            type="text"
            name="motherSource"
            value={motherSource === "Not Applicable" ? "" : motherSource}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Annual Income (Yearly Income)</label>
          <input
            type="text"
            name="motherIncome"
            value={motherIncome}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
            pattern="[0-9]*" // Only accept numeric input
            title="Please enter numbers only"
          />
        </div>
        <div className="text-xl font-semibold mb-5 text-center underline">
          Brother's Income Detail
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Profession</label>
          <input
            type="text"
            name="brotherProfession"
            value={brotherProfession === "Not Applicable" ? "" : brotherProfession}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Source of Income (Salary / Pension / Others)
          </label>
          <input
            type="text"
            name="brotherSource"
            value={brotherSource === "Not Applicable" ? "" : brotherSource}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Annual Income (Yearly Income)</label>
          <input
            type="text"
            name="brotherIncome"
            value={brotherIncome}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
            pattern="[0-9]*" // Only accept numeric input
            title="Please enter numbers only"
          />
        </div>
        <div className="text-xl font-semibold mb-5 text-center underline">
          Sister's Income Detail
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Profession</label>
          <input
            type="text"
            name="sisterProfession"
            value={sisterProfession === "Not Applicable" ? "" : sisterProfession}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Source of Income (Salary / Pension / Others)
          </label>
          <input
            type="text"
            name="sisterSource"
            value={sisterSource === "Not Applicable" ? "" : sisterSource}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Annual Income (Yearly Income)</label>
          <input
            type="text"
            name="sisterIncome"
            value={sisterIncome}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
            pattern="[0-9]*" // Only accept numeric input
            title="Please enter numbers only"
          />
        </div>
        <div className="text-xl font-semibold mb-5 text-center underline">
          Guardian's Income Detail
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Profession</label>
          <input
            type="text"
            name="guardianProfession"
            value={guardianProfession === "Not Applicable" ? "" : guardianProfession}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">
            Source of Income (Salary / Pension / Others)
          </label>
          <input
            type="text"
            name="guardianSource"
            value={guardianSource === "Not Applicable" ? "" : guardianSource}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Annual Income (Yearly Income)</label>
          <input
            type="text"
            name="guardianIncome"
            value={guardianIncome}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
            pattern="[0-9]*" // Only accept numeric input
            title="Please enter numbers only"
          />
        </div>
      </div>
      <div className="flex justify-between mt-8">
        <button
          type="button"
          onClick={prevStep}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline"
        >
          Previous
        </button>
        <button
          type="submit"
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline ${
            submitting ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={submitting}
        >
          {submitting ? "Please wait..." : "Next"}
        </button>
      </div>
    </form>
  );
};

export default FinancialInformation;
