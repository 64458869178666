import React, { useEffect, useState } from "react";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default function GeneratePDF({ formData, setFormData }) {
  const { personal, academic, family, finance, property, siblings } = formData;

  // const [familyMembers, setFamilyMembers] = useState([]);
  // const [siblings, setSiblings] = useState([]);
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [candidatePhoto, setCandidatePhoto] = useState(null);
  // console.log("Sibling",setSiblingsScholarship)
  // Candidate Image
  // Candidate Image
  // Load candidate photo
  const image = new Image();
  // let candidatePhoto = null;
  //  image.src = URL.createObjectURL(formData?.photo);
  //  console.log("Candidate",image)
  // const userImageForPdf = new Image();
  // userImageForPdf.src = formData?.image;

  // 1.Academic
  const tableData1 = [
    [
      "Apply as",
      ":",
      formData?.academic.applyAs || "N/A",
      "University Reg. No #",
      ":",
      formData?.academic.registrationNumber || "N/A",
    ],
    [
      "Year of Admission",
      ":",
      formData?.academic.yearOfAdmission || "N/A",
      "University / Institute",
      ":",
      formData?.academic.universityName || "N/A",
    ],
    [
      "CGPA / Percentage",
      ":",
      (formData?.academic.gradeSystem === "FirstYearStudent" &&
        "First Year Student") ||
        (formData?.academic.gradeSystem === "Percentage" &&
          formData?.academic.percentage) ||
        (formData?.academic.gradeSystem === "GPA" && formData?.academic.gpa) ||
        "N/A", // Ensure this resolves to a string or empty string
      "Discipline",
      ":",
      formData?.academic.discipline || "N/A", // Ensure discipline is defined or fallback to an empty string
    ],
  ];

  //2. Personal
  const tableData2 = [
    [
      "First Name",
      ":",
      formData?.firstName || "N/A",
      "Last Name",
      ":",
      formData?.lastName || "N/A",
    ],
    [
      "Father / Guardian Name",
      ":",
      formData?.family.familyMembers &&
        (formData?.family.familyMembers?.find(
          (member) =>
            member.relationship === "Father" ||
            member.relationship === "Guardian"
        )
          ? formData?.family.familyMembers
              ?.map((member) => {
                if (
                  member.relationship === "Father" ||
                  member.relationship === "Guardian"
                ) {
                  return member.name;
                }
                return null;
              })
              .filter((name) => name !== null)
              .join(", ")
          : "N/A"),
      "CNIC No. of Student",
      ":",
      formData?.cnicStudent || "N/A", // Assuming cnicStudent can be "N/A" if not provided
    ],
    [
      "Phone Number",
      ":",
      formData?.mobileStudent || "N/A",
      "Phone Residence",
      ":",
      formData?.contactNumber || "N/A",
    ],
    [
      "Email",
      ":",
      formData?.email || "N/A",
      "Domicile",
      ":",
      formData?.district || "N/A",
    ],
  ];

  // Details of Family members
  const headers = [
    "Name",
    "Age",
    "Relationship",
    "Status",
    "Occupation",
    "Organisation",
  ];

  // const data = students.map((student) => [
  //   student.name,
  //   `${student.age} Years`,
  //   student.relationship,
  //   student.status,
  //   student.occupation,
  //   student.organisation,
  // ]);

  const data = formData?.family.familyMembers?.map((member) => [
    member.name || "N/A",
    `${member.age} Years` || "N/A",
    member.relationship || "N/A",
    member.maritalStatus || "N/A",
    member.occupation || "N/A",
    member.organization || "N/A",
  ]);
  // 3. Financial Details
  // Residential Property / Immovable Assets Detail

  const propertyHeaders = [
    "Property Type",
    "Exact Location",
    "Area (With Units) ",
    "Value",
  ];

  const propertyData = [
    [
      formData?.property.propertyType === "apartment"
        ? "Apartment"
        : formData?.property.propertyType === "bungalow"
        ? "Bungalow"
        : "N/A",
      formData?.property.propertyAddress || "",
      `${formData?.property.propertyArea || "N/A"} ${
        formData?.property.propertyUnit === "acres"
          ? "Acres"
          : formData?.property.propertyUnit === "sqft"
          ? "Sq Ft."
          : formData?.property.propertyUnit === "sqyds"
          ? "Sq. Yds."
          : ""
      }`,
      formData?.property.propertyValue || "N/A",
    ],
    [
      formData?.property.assetType === "notApplicable"
        ? "Not Applicable"
        : formData?.property.assetType || "N/A", // Display "Not Applicable" if assetType is "notApplicable"
      formData?.property.assetAddress || "N/A",
      `${formData?.property.assetArea || "N/A"} ${
        formData?.property.assetUnit === "acres"
          ? "Acres"
          : formData?.property.assetUnit === "sqft"
          ? "Sq Ft."
          : formData?.property.assetUnit === "sqyds"
          ? "Sq. Yds."
          : ""
      }`,
      formData?.property.assetValue || "N/A",
    ],
  ];

  // Source of Income
  const incomeHeaders = [
    "",
    "Father's",
    "Mother's",
    "Brother's",
    "Sister's",
    "Guardian's /\nOther's",
  ];

  let incomeData = [
    [
      "Profession",
      formData?.finance.fatherProfession || "N/A",
      formData?.finance.motherProfession || "N/A",
      formData?.finance.brotherProfession || "N/A",
      formData?.finance.sisterProfession || "N/A",
      formData?.finance.guardianProfession || "N/A",
    ],
    [
      "Salary / Pension",
      formData?.finance.fatherSource || "N/A",
      formData?.finance.motherSource || "N/A",
      formData?.finance.brotherSource || "N/A",
      formData?.finance.sisterSource || "N/A",
      formData?.finance.guardianSource || "N/A",
    ],
    [
      "Income from all Sources",
      formData?.finance.fatherIncome || "N/A",
      formData?.finance.motherIncome || "N/A",
      formData?.finance.brotherIncome || "N/A",
      formData?.finance.sisterIncome || "N/A",
      formData?.finance.guardianIncome || "N/A",
    ],
  ];
  // Total Income Per Annum

  const tincomeData = [
    ["Total Income\nPer Annum", formData?.finance.totalIncome || "N/A"],
  ];

  const siblingHeaders = ["Name", "Relationship", "University"];
  const siblingData = [
    [
      formData?.siblings.siblingsScholarship?.name || "N/A",
      formData?.siblings.siblingsScholarship?.relationship || "N/A",
      formData?.siblings.siblingsScholarship?.university || "N/A",
    ],
  ];

  // Instruction

  const tableData3 = [
    [
      "1. Copy of Student Identity Card of the University / Institute.",
      "2. Copy of Student CNIC/Copy of CNIC of Father / Guardian.",
    ],
    [
      "3. Copy of Fee Receipt of the University / Institute (Last Paid).",
      " 4. Copy of Certificate of Domicile of the student.",
    ],
    [
      "5. Copy of Permanent Residence Certificate of Student.",
      "6. Copy of Domicile Certificate of Father / Mother / Guardian.",
    ],
    [
      "7. Copy of Academic Certificates including MarkSheets of previous year.",
      "8. Copy of House documents being the evidence of Residence/Rent document.",
    ],
    [
      "9. Original Salary Slip of Father / Guardian if employed in Government / Semi Government / Private organization.",
      "10. Original Income Certificate issued by Mukhtarkar Revenue of concerned District in case Father / Guardian is a non-salary person i.e. Business man / Landlord etc., along with Bank Statement of last six (6) months of personal and Business account.",
    ],
    [
      "11. Original Copy of Income Assessment / Return of Income duly issued by Income Tax Department, Government of Pakistan.",
      "12. Copies of Utility Bills: Electricity / Gas / Telephone (Last paid).",
    ],
    [
      "13. Original Affidavit on Rs. 50/= Stamp paper.",
      "14. Incomplete applications will not be considered.",
    ],
  ];

  useEffect(() => {
    if (formData && formData.photo) {
      image.onload = () => {
        setCandidatePhoto(image);
      };
      if (typeof formData.photo === "string") {
        image.src = formData.photo;
      } else if (formData.photo instanceof File) {
        const reader = new FileReader();
        reader.onload = (e) => {
          image.src = e.target.result;
        };
        reader.readAsDataURL(formData && formData.photo);
      }
    }
  }, [formData, downloadFlag]);

  const handleDownloadPDF = () => {
    if (formData) {
      // console.log("Siblings",setSiblings)
      // console.log("PDF DATA",formData);
      // Create a new PDF document
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "legal",
      });

      // Format and add the data to the PDF document

      image.onload = function () {
        doc.rect(170, 50, 30, 35);
        doc.setFontSize(11);
        // doc.text(
        //   "Please Attach\nPhoto\nPassport\nSize\n1.5in X 2in",
        //   185,
        //   60,
        //   null,
        //   null,
        //   "center"
        // );

        // doc.addImage(this, "png", 170, 50, 30, 35);
        doc.addImage(this, "png", 5, 5, 18, 22);
        doc.setFontSize(13.5).setFont(undefined, "bold");
        doc.text("GOVERNMENT OF SINDH", 123, 10);
        doc.text("COLLEGE EDUCATION DEPARTMENT", 108, 16);
        doc.text("SINDH EDUCATIONAL ENDOWMENT FUND", 102, 22);
        doc.setFontSize(11.5).setFont(undefined, "bold");
        doc.text("REGISTERED VIDE TRUST DEED NO. 625/2020", 107, 28);
        doc.setFontSize(17).setFont(undefined, "bold");
        doc.text("Application for Award of Endowment Fund Scholarship", 26, 37);
        doc.text("Academic Year 2023-24", 70, 44);
        doc.setLineWidth(0.8);
        doc.line(10, 46, 200, 46);
        doc.setFontSize(14).setFont(undefined, "bold");
        doc.text("PLEASE READ ALL THE INSTRUCTIONS CAREFULLY", 10, 56);
        doc.setFontSize(10).setFont(undefined, "normal");
        doc.text(
          "1. Final Year, Over-sea or Self-Financed Students are not eligible for Financial Assistance.",
          10,
          64
        );
        doc.text(
          "2. Please Submit the Hard Copy of this Form in your university's concern department.",
          10,
          69
        );
        doc.setLineWidth(0.5);
        doc.line(10, 76, 150, 76);
        doc.setFontSize(15).setFont(undefined, "bold");
        doc.text("1. ACADEMIC INFORMATION", 10, 85);
        doc.line(10, 88, 200, 88);

        doc.autoTable({
          body: tableData1,
          startY: 92,
          columnStyles: {
            0: { fillColor: "#fff", fontSize: 11, columnWidth: 25 },
            1: { fillColor: "#fff", fontSize: 11, columnWidth: 5 },
            2: {
              fillColor: "#fff",
              fontSize: 12,
              columnWidth: 45,
              fontStyle: "bold",
            },
            3: { fillColor: "#fff", fontSize: 11, columnWidth: 25 },
            4: { fillColor: "#fff", fontSize: 11, columnWidth: 5 },
            5: {
              fillColor: "#fff",
              fontSize: 11.5,
              columnWidth: 85,
              fontStyle: "bold",
            },
          },
        });

        doc.setLineWidth(0.5);
        doc.line(10, 138, 200, 138);
        doc.setFontSize(15).setFont(undefined, "bold");
        doc.text("2. PERSONAL INFORMATION", 10, 147);
        doc.line(10, 150, 200, 150);
        doc.autoTable({
          body: tableData2,
          startY: 154,
          columnStyles: {
            0: { fillColor: "#fff", fontSize: 11, columnWidth: 25 },
            1: { fillColor: "#fff", fontSize: 11, columnWidth: 5 },
            2: {
              fillColor: "#fff",
              fontSize: 12,
              columnWidth: 60,
              fontStyle: "bold",
            },
            3: { fillColor: "#fff", fontSize: 11, columnWidth: 25 },
            4: { fillColor: "#fff", fontSize: 11, columnWidth: 5 },
            5: {
              fillColor: "#fff",
              fontSize: 12,
              columnWidth: 60,
              fontStyle: "bold",
            },
          },
        });
        // doc.line(10, 220, 200, 220);
        doc.setFontSize(13.5).setFont(undefined, "bold");
        doc.text("Details of Family Members", 10, 208);
        doc.line(10, 211, 75, 211);
        doc.autoTable({
          head: [headers],
          body: data,
          startY: 215,
          headStyles: {
            textColor: [0, 0, 0], // set text color to black
            fillColor: false, // remove background color
            halign: "center", // Center align header text
          },
          footStyles: {
            textColor: [0, 0, 0], // set text color to black
            fillColor: false, // remove background color
          },
          didParseCell: function (data) {
            data.cell.styles.fillColor = false; // remove background color
            data.cell.styles.lineWidth = 0.1; // set border width
            data.cell.styles.lineColor = [0, 0, 0]; // set border color to black
            data.cell.styles.textColor = [0, 0, 0]; // set text color to black
          },
          didDrawPage: function (data) {
            // do something when a page is drawn
          },
        });
        doc.setLineWidth(0.5);
        doc.line(10, 345, 203, 345);
        doc.setFontSize(10).setFont(undefined, "bold");
        doc.text("Page 1 of 2", 100, 350);

        // Add a new page to the document
        doc.addPage();

        doc.setFontSize(10).setFont(undefined, "bold");
        doc.text("Page 2 of 2", 100, 10);

        doc.setLineWidth(0.5);
        doc.line(10, 13, 200, 13);
        doc.setFontSize(15).setFont(undefined, "bold");
        doc.text("3. FINANCIAL DETAILS", 10, 22);
        doc.line(10, 25, 200, 25);

        doc.setFontSize(13).setFont(undefined, "bold");
        doc.text("Residential Property / Immovable Assets Detail", 10, 31);
        doc.line(10, 33, 120, 33);

        doc.autoTable({
          head: [propertyHeaders],
          body: propertyData,
          startY: 36,
          headStyles: {
            textColor: [0, 0, 0], // set text color to black
            fillColor: false, // remove background color
            halign: "center", // Center align header text
          },
          footStyles: {
            textColor: [0, 0, 0], // set text color to black
            fillColor: false, // remove background color
          },
          didParseCell: function (data) {
            data.cell.styles.fillColor = false; // remove background color
            data.cell.styles.lineWidth = 0.1; // set border width
            data.cell.styles.lineColor = [0, 0, 0]; // set border color to black
            data.cell.styles.textColor = [0, 0, 0]; // set text color to black
          },
        });
        doc.setFontSize(13).setFont(undefined, "bold");
        doc.text(
          "Source of Income (Provide DOCUMENTARY EVIDENCE for Income)",
          10,
          70
        );
        doc.line(10, 73, 170, 73);

        doc.autoTable({
          head: [incomeHeaders],
          body: incomeData,
          startY: 76,
          headStyles: {
            textColor: [0, 0, 0], // set text color to black
            fillColor: false, // remove background color
            halign: "center", // Center align header text
          },
          footStyles: {
            textColor: [0, 0, 0], // set text color to black
            fillColor: false, // remove background color
          },
          didParseCell: function (data) {
            data.cell.styles.fillColor = false; // remove background color
            data.cell.styles.lineWidth = 0.1; // set border width
            data.cell.styles.lineColor = [0, 0, 0]; // set border color to black
            data.cell.styles.textColor = [0, 0, 0]; // set text color to black
          },
        });
        doc.autoTable({
          body: tincomeData,
          startY: 124,

          footStyles: {
            textColor: [0, 0, 0], // set text color to black
            fillColor: false, // remove background color
          },
          didParseCell: function (data) {
            data.cell.styles.fillColor = false; // remove background color
            data.cell.styles.lineWidth = 0.1; // set border width
            data.cell.styles.lineColor = [0, 0, 0]; // set border color to black
            data.cell.styles.textColor = [0, 0, 0]; // set text color to black
          },
        });
        doc.setFontSize(13).setFont(undefined, "bold");
        doc.text("Details Sibling Already Availing SEEF Scholarship", 10, 142);
        doc.line(10, 144, 120, 144);
        doc.autoTable({
          head: [siblingHeaders],
          body: siblingData,
          startY: 146,
          headStyles: {
            textColor: [0, 0, 0], // set text color to black
            fillColor: false, // remove background color
            halign: "center", // Center align header text
          },
          footStyles: {
            textColor: [0, 0, 0], // set text color to black
            fillColor: false, // remove background color
          },
          didParseCell: function (data) {
            data.cell.styles.fillColor = false; // remove background color
            data.cell.styles.lineWidth = 0.1; // set border width
            data.cell.styles.lineColor = [0, 0, 0]; // set border color to black
            data.cell.styles.textColor = [0, 0, 0]; // set text color to black
          },
        });
        doc.line(10, 163, 200, 163);
        doc.setFontSize(14).setFont(undefined, "bold");
        doc.text("4. Required Documents", 10, 170);
        doc.line(10, 173, 200, 173);
        doc.autoTable({
          body: tableData3,
          startY: 176,
          columnStyles: {
            0: {
              fillColor: "",
              fontSize: 10.5,
              columnWidth: 85,
              cellPadding: { left: 1 },
            },
            1: {
              fillColor: "",
              fontSize: 10.5,
              columnWidth: 100,
              cellPadding: { left: 7 },
            },
          },
          didParseCell: function (data) {
            //         //     // Check if it's the first cell

            // Draw the checkbox
            const checkboxSize = 3;
            doc.setLineWidth(0.1);
            doc.rect(10, 176.5, checkboxSize, checkboxSize, "S");
            doc.rect(100, 176.5, checkboxSize, checkboxSize, "S");
            doc.rect(10, 184, checkboxSize, checkboxSize, "S");
            doc.rect(100, 184, checkboxSize, checkboxSize, "S");
            doc.rect(10, 193, checkboxSize, checkboxSize, "S");
            doc.rect(100, 193, checkboxSize, checkboxSize, "S");
            doc.rect(10, 201, checkboxSize, checkboxSize, "S");
            doc.rect(100, 201, checkboxSize, checkboxSize, "S");
            doc.rect(10, 210, checkboxSize, checkboxSize, "S");
            doc.rect(100, 210, checkboxSize, checkboxSize, "S");
            doc.rect(10, 232, checkboxSize, checkboxSize, "S");
            doc.rect(100, 232, checkboxSize, checkboxSize, "S");
            doc.rect(10, 244.5, checkboxSize, checkboxSize, "S");
            doc.rect(100, 244.5, checkboxSize, checkboxSize, "S");
          },
        });
        doc.line(10, 251, 200, 251);
        doc.setFontSize(14).setFont(undefined, "bold");
        doc.text("5. AFFIDAVIT", 10, 257);
        doc.line(10, 260, 200, 260);
        doc.setFontSize(13);
        doc.text(
          "Submit the Affidavit, duly printed on Stamp paper of value Rs. 50/, alongwith this form.",
          10,
          265
        );

        doc.setLineWidth(0.5);
        doc.line(195, 276, 145, 276);
        doc.setFontSize(10).setFont(undefined, "bold");
        doc.text("Signature of the Student", 150, 280);

        doc.setLineWidth(0.5);
        doc.line(82, 276, 15, 276);
        doc.setFontSize(10).setFont(undefined, "bold");
        doc.text("Signature of the Parents/Guardian", 20, 280);

        doc.rect(8, 288, 200, 50);
        doc.setFontSize(14).setFont(undefined, "bold");
        doc.text("Attestation from University", 12, 295);
        doc.line(115, 320, 160, 320);
        doc.setFontSize(12);
        doc.text("Signature & Stamp", 120, 325);
        doc.text("Concerned Officer", 120, 330);

        // ... (Add your PDF content generation code here using the formData)

        // Save the PDF
        doc.save("SEEF Scholarship Form.pdf");

        setDownloadFlag(false);
        // setFormData(null);
        // setFamilyMembers([]);
        // setProperty([]);
        // setIncome([]);
        // setsiblingsScholarship([]);
      };
      // Add candidate photo to PDF
      if (candidatePhoto) {
        doc.addImage(candidatePhoto, "JPEG", 170, 50, 30, 35); // Adjust position and size as needed
      } else {
        console.warn("Candidate photo could not be loaded.");
      }
    }
    image.src = "/logo.png";
  };

  return (
    <form className="flex flex-col items-center justify-center">
      <div className="font-bold underline text-center text-xl mb-4">
        Documents Required along With the Form
      </div>
      <div className="m-4">
        {tableData3.map((item, index) => (
          <div key={index} className="mb-2 font-semibold">
            {item.map((text, i) => (
              <p key={i} className="text-lg">
                {text}
              </p>
            ))}
          </div>
        ))}
      </div>
      <div className="flex items-center">
        <button
          type="button"
          onClick={handleDownloadPDF}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Download Scholarship Form
        </button>
      </div>
    </form>
  );
}
