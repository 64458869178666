// import React from "react";
import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Instruction from "../instructions/Instruction";
import Documents from "../Documents/documents";
import axios from "axios";
import AuthUser from "../Loji/loji"; // Adjust the path based on your actual file structure

// import AFFIDAVIT from "public/Affidavit.pdf";
const Preview = ({ formData, prevStep, nextStep }) => {
  const {
    firstName,
    lastName,
    cnicStudent,
    mobileStudent,
    district,
    gender,
    contactNumber,
    email,
    photo,
    academic,
    family,
    finance,
    property,
    siblings,
  } = formData;

  const [photoURL, setPhotoURL] = useState(null);
  const [data, setData] = useState();
  const { http } = AuthUser();
  const SEEF_FORM = "form-submit2850";
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "Affidavit.pdf";
    link.download = "Sample-Affidavit-PDF";
    link.target = "_blank";
    link.rel = "noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (formData && formData?.photo) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoURL(reader.result);
      };
      reader.readAsDataURL(formData && formData?.photo);
    }
  }, []);

  if (!formData) {
    return <div>Loading...</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("Last Step Data", formData);
  
    const dataToSend = {
      finance: formData.finance,
      familyMembers: formData.family.familyMembers,
      siblingsscholarship: formData.siblings.siblingsScholarship,
      academic: formData.academic,
      property: formData.property,
      firstName,
      lastName,
      cnicStudent,
      mobileStudent,
      district,
      gender,
      contactNumber,
      email,
      photo,
    };
  
    // console.log("sending to backend", dataToSend);
  
    try {
      const response = await http.post(SEEF_FORM, dataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log("Form data submitted successfully:", response.data);
  
      window.alert(response.data.message); // Display success message
  
      nextStep();
    } catch (error) {
      console.error("Form submission error:", error);
  
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const errorMessage =
          error.response.data.message || "Something went wrong!";
        window.alert(errorMessage);
      } else if (error.request) {
        // The request was made but no response was received
        window.alert("No response received from server!");
      } else {
        // Something happened in setting up the request that triggered an Error
        window.alert("Error while submitting form!");
      }
    }
  };
  



  const fatherGuardian = formData.family?.familyMembers.find(
    (member) =>
      member?.relationship === "Father" || member?.relationship === "Guardian"
  );
  return (
    <div className="">
      <div className="shadow-2xl bg-gray-100 p-6  justify-between    text-center mx-auto">
        {/* <Header /> */}
        <div className="p-2 mt-6 text-2xl rounded-lg font-bold bg-red-500 -mb-6">
          Read the Form Carefully before clicking on SUBMIT button, because once
          the form is submitted it cannot be Edited or Corrected.
        </div>
        <Instruction />
        {/* Photo Box */}
        {/* Photo Box */}
        {/* Right Content - Photo */}
        <div className="flex justify-end">
          {/* <div className="mx-3 sm:mx-6"> */}
          <div className="w-24 h-32 border-2 border-gray-700">
            {photoURL && (
              <img
                src={photoURL}
                alt="Student Photo"
                className="w-full h-full object-cover"
              />
            )}
            {/* </div> */}
          </div>
        </div>

        {/* Academic Information */}
        <div className="text-left mx-3 sm:mx-6">
          <h1 className="font-bold text-2xl">1. ACADEMIC INFORMATION</h1>
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />

          <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
            <div className="mb-4 md:w-1/2">
              <label htmlFor="fresh" className="block font-bold mb-2">
                Apply as <span className="text-red-700 font-semibold">*</span>:{" "}
                {academic?.applyAs}
              </label>
            </div>
            <div className="mb-4 md:w-1/2">
              <label htmlFor="roll" className="block font-bold mb-2">
                University Reg. No #{" "}
                <span className="text-red-700 font-semibold">*</span>:{" "}
                {academic?.registrationNumber}
              </label>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
            <div className="mb-4 md:w-1/2">
              <label htmlFor="admissionYear" className="block font-bold mb-2">
                Year of Admission{" "}
                <span className="text-red-700 font-semibold">*</span>:{" "}
                {academic?.yearOfAdmission}
              </label>
            </div>
            <div className="mb-4 md:w-1/2">
              <label htmlFor="university" className="block font-bold mb-2">
                Name of University / Institute{" "}
                <span className="text-red-700 font-semibold">*</span>:{" "}
                {academic?.universityName}
              </label>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
            <div className="mb-4 md:w-1/2">
              <label htmlFor="grade-system" className="block font-bold mb-2">
                {academic?.gradeSystem === "GPA"
                  ? "GPA"
                  : academic?.gradeSystem === "Percentage"
                  ? "Percentage"
                  : "First Year Student"}
                <span className="text-red-700 font-semibold">*</span>:
                {academic?.gradeSystem === "GPA"
                  ? academic?.gpa
                  : academic?.gradeSystem === "Percentage"
                  ? academic?.percentage
                  : "First Year Student"}
              </label>
            </div>

            <div className="mb-4 md:w-1/2">
              <label htmlFor="discipline" className="block font-bold mb-2">
                Discipline <span className="text-red-700 font-semibold">*</span>
                : {academic?.discipline}
              </label>
            </div>
          </div>
        </div>

        {/* Personal Information */}
        <div className="text-left mx-3 sm:mx-6">
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
          <h1 className="font-bold text-2xl text-left">
            2. PERSONAL INFORMATION
          </h1>
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />

          <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
            <div className="mb-4 md:w-1/2">
              <label htmlFor="first" className="block font-bold mb-2">
                First Name{" "}
                <span className="text-red-700 font-semibold required:">*</span>:{" "}
                {firstName}
              </label>
            </div>
            <div className="mb-4 md:w-1/2">
              <label htmlFor="last" className="block font-bold mb-2">
                Last Name{" "}
                <span className="text-red-700 font-semibold required:">*</span>:{" "}
                {lastName}
              </label>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
            <div className="mb-4 md:w-1/2">
              <label htmlFor="parent" className="block font-bold mb-2">
                CNIC No.{" "}
                <span className="text-red-700 font-semibold required:">*</span>:{" "}
                {cnicStudent}
              </label>
            </div>
            <div className="mb-4 md:w-1/2">
              <label htmlFor="cnic" className="block font-bold mb-2">
                Gender{" "}
                <span className="text-red-700 font-semibold required:">*</span>:{" "}
                {gender}
              </label>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
            <div className="mb-4 md:w-1/2">
              <label htmlFor="parent" className="block font-bold mb-2">
                Father/Guardian Name{" "}
                <span className="text-red-700 font-semibold required">*</span>:{" "}
                {fatherGuardian ? fatherGuardian.name : ""}
              </label>
            </div>
            <div className="mb-4 md:w-1/2">
              <label htmlFor="cnic" className="block font-bold mb-2">
                Domicile{" "}
                <span className="text-red-700 font-semibold required:">*</span>:{" "}
                {district}
              </label>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
            <div className="mb-4 md:w-1/2">
              <label htmlFor="cell" className="block font-bold mb-2">
                Phone Number{" "}
                <span className="text-red-700 font-semibold required:">*</span>:{" "}
                {contactNumber}
              </label>
            </div>
            <div className="mb-4 md:w-1/2">
              <label htmlFor="home" className="block font-bold mb-2">
                Phone Residence{" "}
                <span className="text-red-700 font-semibold required:">*</span>:{" "}
                {mobileStudent}
              </label>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
            <div className="mb-4 md:w-1/2">
              <label htmlFor="email" className="block font-bold mb-2">
                Email{" "}
                <span className="text-red-700 font-semibold required:">*</span>:{" "}
                {email}
              </label>
            </div>
          </div>
        </div>

        {/* Family Members */}
        <div className="mx-3 sm:mx-6">
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
          <h1 className="font-bold text-2xl text-left">
            Details of Family Members
          </h1>
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />

          {family?.familyMembers.length === 0 ? (
            <p>No Family member added yet.</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full border-collapse border">
                <thead>
                  <tr>
                    <th className="border px-4 py-2 whitespace-nowrap">Name</th>
                    <th className="border px-4 py-2 whitespace-nowrap">Age</th>
                    <th className="border px-4 py-2 whitespace-nowrap">
                      Relationship
                    </th>
                    <th className="border px-4 py-2 whitespace-nowrap">
                      Marital status
                    </th>
                    <th className="border px-4 py-2 whitespace-nowrap">
                      Occupation
                    </th>
                    <th className="border px-4 py-2 whitespace-nowrap">
                      Organisation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {family?.familyMembers.map((member, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-gray-100" : ""}
                    >
                      <td className="border px-4 py-2">{member.name}</td>
                      <td className="border px-4 py-2">{member.age} Years</td>
                      <td className="border px-4 py-2">
                        {member.relationship}
                      </td>
                      <td className="border px-4 py-2">
                        {member.maritalStatus}
                      </td>
                      <td className="border px-4 py-2">{member.occupation}</td>
                      <td className="border px-4 py-2">
                        {member.organization}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {/* finance starts */}
        <div className="mx-3 sm:mx-6">
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
          <h1 className="text-left font-bold text-2xl">
            3. finance INFORMATION
          </h1>

          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
          <div className="font-bold underline text-left mx-6 text-lg py-3">
            RESIDENTIAL PROPERTY / IMMOVABLE ASSETS DETAILS
          </div>
        </div>

        <div className="my-4 overflow-x-auto">
          <table className="table-auto w-full sm:table">
            <thead>
              <tr>
                <th className="px-4 py-2 sm:w-1/3 lg:w-1/4">Property Type</th>
                <th className="px-4 py-2 sm:w-1/3 lg:w-1/4">Exact Location</th>
                <th className="px-4 py-2 sm:w-1/3 lg:w-1/4">
                  Area (in Acres/Sq Yards)
                </th>
                <th className="px-4 py-2 sm:w-1/3 lg:w-1/4">Value (PKR)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-4 py-2">{property?.propertyType}</td>
                <td className="border px-4 py-2">
                  {property?.propertyAddress}
                </td>
                <td className="border px-4 py-2">{property?.propertyArea}{property?.propertyUnit}</td>
                <td className="border px-4 py-2">
                  {property?.propertyValue} 
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">{property?.assetType}</td>
                <td className="border px-4 py-2">{property?.assetAddress}</td>
                <td className="border px-4 py-2">{property?.assetArea}{property?.assetUnit}</td>
                <td className="border px-4 py-2">
                  {property?.assetValue} 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* finance ends */}

        {/* source of income starts */}
        <div className="font-bold underline text-left mx-6 text-lg py-3">
          SOURCE OF INCOME PER ANNUM (Provide DOCUMENTARY EVIDENCE FOR INCOME)
        </div>
        <div className="my-4 overflow-x-auto">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="sm:table-cell px-4 py-2"></th>
                <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">Father&apos;s</th>
                <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">Mother&apos;s</th>
                <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">Brother&apos;s</th>
                <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">Sister&apos;s</th>
                <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">
                  Guardian&apos;s / Other&apos;s
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="sm:table-cell border px-4 py-2 font-bold">
                  Profession
                </td>
                <td className="border px-4 py-2">
                  {finance?.fatherProfession}
                </td>
                <td className="border px-4 py-2">
                  {finance?.motherProfession}
                </td>
                <td className="border px-4 py-2">
                  {finance?.brotherProfession}
                </td>
                <td className="border px-4 py-2">
                  {finance?.sisterProfession}
                </td>
                <td className="border px-4 py-2">
                  {finance?.guardianProfession}
                </td>
              </tr>
              <tr>
                <td className="sm:table-cell border px-4 py-2 font-bold">
                  Salary/Pension
                </td>
                <td className="border px-4 py-2">{finance?.fatherSource}</td>
                <td className="border px-4 py-2">{finance?.motherSource}</td>
                <td className="border px-4 py-2">{finance?.brotherSource}</td>
                <td className="border px-4 py-2">{finance?.sisterSource}</td>
                <td className="border px-4 py-2">{finance?.guardianSource}</td>
              </tr>
              <tr>
                <td className="sm:table-cell border px-4 py-2 font-bold">
                  Per Annum Income from all Sources
                </td>
                <td className="border px-4 py-2">{finance?.fatherIncome}</td>
                <td className="border px-4 py-2">{finance?.motherIncome}</td>
                <td className="border px-4 py-2">{finance?.brotherIncome}</td>
                <td className="border px-4 py-2">{finance?.sisterIncome}</td>
                <td className="border px-4 py-2">{finance?.guardianIncome}</td>
              </tr>
              {/* Rs. {totalIncome}/- */}
            </tbody>
          </table>
          <table>
            <tbody>
              <tr className="justify-center">
                <td className="sm:table-cell border w-2/12 px-4 py-2 text-xl font-bold">
                  Total Income Per Annum
                </td>
                <td className="border px-4 py-2 w-10/12 bg-white font-semibold text-2xl">
                  Rs. {finance?.totalIncome}/-
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* source of income ends */}

        <div className="flex flex-col sm:flex-row gap-x-3 items-start mx-4">
          <p className="text-xl font-bold mb-2 underline">
            Sibling(s) availaing SEEF Scholarship?{" "}
            {siblings?.siblingsScholarship?.siblingsReceivingScholarship}
          </p>
        </div>

        {siblings?.siblingsScholarship?.siblingsReceivingScholarship ===
        "yes" ? (
          <div className="mx-4 items-start">
            <p className="mx-4 mt-4 mb-2 text-lg text-left font-semibold">
              Details of Sibling Availing SEEF Scholarship:
            </p>

            <table className="border-collapse w-full border border-gray-300">
              <thead>
                <tr>
                  <th className="border border-gray-400 py-2 w-3/12 px-4">
                    Name
                  </th>
                  <th className="border border-gray-400 w-3/12 py-2 px-4">
                    Relationship
                  </th>
                  <th className="border border-gray-400 py-2 px-4">
                    University
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-400 bg-white py-2 w-3/12 px-4">
                    {siblings?.siblingsScholarship.name}
                  </td>
                  <td className="border border-gray-400 py-2 bg-white w-3/12 px-4">
                    {siblings?.siblingsScholarship?.relationship}
                  </td>
                  <td className="border border-gray-400 py-2 bg-white px-4">
                    {siblings?.siblingsScholarship.university}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
        <Documents />
        {/* Attachments */}
        {/* <div className="mx-3 sm:mx-6">
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
          <h1 className="font-bold text-2xl text-left">Attachments</h1>
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />

          {attachments.length === 0 ? (
            <p>No attachments uploaded.</p>
          ) : (
            <ul className="list-disc ml-6">
              {attachments.map((attachment, index) => (
                <li key={index}>{attachment}</li>
              ))}
            </ul>
          )}
        </div> */}

        {/* Sample Affidavit */}

        <div className="mx-3 sm:mx-6">
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
          <h1 className="text-left font-bold text-2xl">
            5. DOWNLOAD SAMPLE AFFIDAVIT
          </h1>
          <hr className="border-t-2 border-gray-700 my-5 rounded-none" />

          <div className="flex text-red-600 gap-x-2 text-red-00 items-center font-bold text-left py-3">
            Before Submitting the Form Online, Download the Sample Affidavit
            Form.
            <button
              onClick={handleDownload}
              className="px-6 py-2 bg-blue-600 text-white rounded-md"
            >
              Download SAMPLE AFFIDAVIT
            </button>
          </div>
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            onClick={prevStep}
            className="px-6 py-2 bg-gray-600 text-white rounded-md"
          >
            Back
          </button>

          <button
            type="button"
            onClick={handleSubmit}
            className="px-6 py-2 bg-blue-600 text-white rounded-md"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Preview;
