import React, { useState } from 'react';
import Sidebar from './Structure/Sidebar';
import MainContent from './Structure/MainContent';

const App = () => {
  const [selectedMenu, setSelectedMenu] = useState('Dashboard');
  const [isOpen, setIsOpen] = useState(false); // State to manage sidebar open/close

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex ">
      <Sidebar selectedMenu={selectedMenu} handleMenuClick={handleMenuClick} isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <MainContent selectedMenu={selectedMenu} isOpen={isOpen} />
    </div>
  );
};

export default App;
