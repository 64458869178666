import React from "react";
import Header from "../../../Header/Header";
const Preview = ({ data }) => {
  console.log("Data", data);
  const photoURL = `https://backend.form.form-seef.com/storage/app//public/${data?.personal?.photo}`;
  return (
    <>
        <div className="flex justify-between">
      <div className=" items-center">
        <img
          className="w-20"
          src="/logo.png"
          alt="Logo"
          />
      </div>
      <div className="font-bold">
        <h2 className="text-center md:text-lg lg:text-lg">
          GOVERNMENT OF SINDH
          <p>COLLEGE EDUCATION DEPARTMENT</p>
          <p>SINDH EDUCATIONAL ENDOWMENT FUND</p>
          <p>REGISTERED VIDE TRUST DEED NO. 625/2020</p>

        </h2>
          </div>
      </div>
      <div className="grid justify-items-end">
  <div className=" w-24 h-32 border-2 border-gray-700">
  {photoURL && (
              <img
                src={photoURL}
                alt="Student Photo"
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.onerror = null; // Prevent looping
                  e.target.src = 'path/to/fallback-image.png'; // Fallback image
                }}
              />
            )}
  </div>
</div>
      {/* Academic Information */}
      <div className="text-left mx-3 sm:mx-6">
        <h1 className="font-bold text-2xl">1. ACADEMIC INFORMATION</h1>
        <hr className="border-t-2 border-gray-700 my-5 rounded-none" />

        <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
          <div className="mb-4 md:w-1/2">
            <label htmlFor="fresh" className="block font-bold mb-2">
              Apply as <span className="text-red-700 font-semibold">*</span>:{" "}
              <span className="font-semibold underline ml-5">
                {data?.academic?.applyAs}
                </span>
            </label>
          </div>
          <div className="mb-4 md:w-1/2">
            <label htmlFor="roll" className="block font-bold mb-2">
              University Reg. No #{" "}
              <span className="text-red-700 font-semibold">*</span>:{" "}
              <span className="font-semibold underline ml-5">

              {data?.academic?.registrationNumber}
              </span>
            </label>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
          <div className="mb-4 md:w-1/2">
            <label htmlFor="admissionYear" className="block font-bold mb-2">
              Year of Admission{" "}
              <span className="text-red-700 font-semibold">*</span>:{" "}
              <span className="font-semibold underline ml-5">

              {data?.academic?.yearOfAdmission}
              </span>
            </label>
          </div>
          <div className="mb-4 md:w-1/2">
            <label htmlFor="university" className="block font-bold mb-2">
              Name of University / Institute{" "}
              <span className="text-red-700 font-semibold">*</span>:{" "}
              <span className="font-semibold underline ml-5">
              {data?.academic?.universityName}
              </span>

            </label>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
          <div className="mb-4 md:w-1/2">
            <label htmlFor="grade-system" className="block font-bold mb-2">
              {data?.academic?.gradeSystem === "GPA"
                ? "GPA"
                : data?.academic?.gradeSystem === "Percentage"
                ? "Percentage"
                : "First Year Student"}
              <span className="text-red-700 font-semibold">*</span>:
<span className="font-semibold underline ml-5">

              {data?.academic?.gradeSystem === "GPA"
                ? data?.academic?.gpa
                : data?.academic?.gradeSystem === "Percentage"
                ? data?.academic?.percentage
                : ""}
                </span>
            </label>
          </div>

          <div className="mb-4 md:w-1/2">
            <label htmlFor="discipline" className="block font-bold mb-2">
              Discipline <span className="text-red-700 font-semibold">*</span>:
              <span className="font-semibold underline ml-5">

              {data?.academic?.discipline}
              </span>
            </label>
          </div>
        </div>
      </div>

      {/* Personal Information */}
      <div className="text-left mx-3 sm:mx-6">
        <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
        <h1 className="font-bold text-2xl text-left">
          2. PERSONAL INFORMATION
        </h1>
        <hr className="border-t-2 border-gray-700 my-5 rounded-none" />

        <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
          <div className="mb-4 md:w-1/2">
            <label htmlFor="first" className="block font-bold mb-2">
              First Name{" "}
              <span className="text-red-700 font-semibold required:">*</span>:{" "}
              <span className="font-semibold underline ml-5">

              {data?.personal.firstName}
              </span>
            </label>
          </div>
          <div className="mb-4 md:w-1/2">
            <label htmlFor="last" className="block font-bold mb-2">
              Last Name{" "}
              <span className="text-red-700 font-semibold required:">*</span>:{" "}
              <span className="font-semibold underline ml-5">

              {data?.personal.lastName}
              </span>

            </label>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
          <div className="mb-4 md:w-1/2">
            <label htmlFor="parent" className="block font-bold mb-2">
              CNIC No.{" "}
              <span className="text-red-700 font-semibold required:">*</span>:{" "}
              <span className="font-semibold underline ml-5">

              {data?.personal.cnicStudent}
              </span>
            </label>
          </div>
          <div className="mb-4 md:w-1/2">
            <label htmlFor="cnic" className="block font-bold mb-2">
              Gender{" "}
              <span className="text-red-700 font-semibold required:">*</span>:{" "}
              <span className="font-semibold underline ml-5">

              {data?.personal.gender}
              </span>

            </label>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
          <div className="mb-4 md:w-1/2">
            <label htmlFor="parent" className="block font-bold mb-2">
              Father/Guardian Name{" "}
              <span className="text-red-700 font-semibold required">*</span>:{" "}
  <span className="font-semibold underline ml-5">

  {data?.familyMembers?.length > 0 ? (
    // Find the Father
    data?.familyMembers.find(member => member.relationship === 'Father')?.name ||
    // If not found, then find the Guardian
    data?.familyMembers.find(member => member.relationship === 'Guardian')?.name ||
    // Default empty string if neither is found
    ""
  ) : ""}
  </span>
            </label>
          </div>
          <div className="mb-4 md:w-1/2">
            <label htmlFor="cnic" className="block font-bold mb-2">
              Domicile{" "}
              <span className="text-red-700 font-semibold required:">*</span>:{" "}
         <span className="font-semibold underline ml-5">
          
           {data?.personal.district}
          </span>    
            </label>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
          <div className="mb-4 md:w-1/2">
            <label htmlFor="cell" className="block font-bold mb-2">
              Phone Number{" "}
              <span className="text-red-700 font-semibold required:">*</span>:{" "}
              <span className="font-semibold underline ml-5">

              {data?.personal.mobileStudent}
              </span>
            </label>
          </div>
          <div className="mb-4 md:w-1/2">
            <label htmlFor="home" className="block font-bold mb-2">
              Phone Residence{" "}
              <span className="text-red-700 font-semibold required:">*</span>:{" "}
              <span className="font-semibold underline ml-5">
              {data?.personal.contactNumber}

              </span>
            </label>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between md:gap-4">
          <div className="mb-4 md:w-1/2">
            <label htmlFor="email" className="block font-bold mb-2">
              Email{" "}
              <span className="text-red-700 font-semibold required:">*</span>:{" "}
              <span className="font-semibold underline ml-5">

              {data?.personal.email}
              </span>
            </label>
          </div>
        </div>
      </div>

      {/* Family Members */}
      <div className="mx-3 sm:mx-6">
        <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
        <h1 className="font-bold text-2xl text-left">
          Details of Family Members
        </h1>
        <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
        {data?.familyMembers.length === 0 ? (
        <p>No Family member added yet.</p>) : (
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border">
            <thead>
              <tr>
                <th className="border px-4 py-2 whitespace-nowrap">Name</th>
                <th className="border px-4 py-2 whitespace-nowrap">Age</th>
                <th className="border px-4 py-2 whitespace-nowrap">
                  Relationship
                </th>
                <th className="border px-4 py-2 whitespace-nowrap">
                  Marital status
                </th>
                <th className="border px-4 py-2 whitespace-nowrap">
                  Occupation
                </th>
                <th className="border px-4 py-2 whitespace-nowrap">
                  Organisation
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.familyMembers.map((member, index) => (
              <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-100" : ""}
              >
                <td className="border px-4 py-2">{member.name}</td>
                <td className="border px-4 py-2">{member.age} Years</td>
                <td className="border px-4 py-2">
                  {member.relationship}
                </td>
                <td className="border px-4 py-2">
                  {member.maritalStatus}
                </td>
                <td className="border px-4 py-2">{member.occupation}</td>
                <td className="border px-4 py-2">
                  {member.organization}
                </td>
              </tr>
              ))}
            </tbody>
          </table>
        </div>
        )}
      </div>

      {/* finance starts */}
      <div className="mx-3 sm:mx-6">
        <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
        <h1 className="text-left font-bold text-2xl">3. Financial Information</h1>

        <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
        <div className="font-bold underline text-left mx-6 text-lg py-3">
          RESIDENTIAL PROPERTY / IMMOVABLE ASSETS DETAILS
        </div>
      </div>

      <div className="my-4">
        <table className="table-auto w-full sm:table">
          <thead>
            <tr>
              <th className="px-4 py-2 sm:w-1/3 lg:w-1/4">Property Type</th>
              <th className="px-4 py-2 sm:w-1/3 lg:w-1/4">Exact Location</th>
              <th className="px-4 py-2 sm:w-1/3 lg:w-1/4">
                Area (with Units)
              </th>
              <th className="px-4 py-2 sm:w-1/3 lg:w-1/4">Value (PKR)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 text-center py-2">{data?.property.propertyType}</td>
              <td className="border px-4 py-2 w-4/12">
                {data?.property.propertyAddress}
              </td>
              <td className="border px-4 text-center  py-2">{data?.property.propertyArea}{data?.property.propertyUnit}</td>
              <td className="border px-4 text-center py-2">
  {data?.property?.propertyValue ?? 'N/A'}
</td>
            </tr>
            <tr>
            <td className="border px-4 text-center py-2">
  {data?.property.assetType === "notApplicable" ? "Not Applicable" : data?.property.assetType}
</td>
              <td className="border px-4 py-2">{data?.property.assetAddress ?? 'N/A'}</td>
              <td className="border text-center px-4 py-2">
  {(data?.property.assetArea || data?.property.assetUnit) 
    ? `${data?.property.assetArea ?? ''} ${data?.property.assetUnit ?? ''}`.trim()
    : 'N/A'
  }
</td>
              <td className="border text-center px-4 py-2">
                {data?.property.assetValue ?? 'N/A' } 
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* finance ends */}

      {/* source of income starts */}
      <div className="font-bold underline text-left mx-6 text-lg py-3">
        SOURCE OF INCOME PER ANNUM (Provide DOCUMENTARY EVIDENCE FOR INCOME)
      </div>
      <div className="my-4 ">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="sm:table-cell px-4 py-2"></th>
              <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">Father&apos;s</th>
              <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">Mother&apos;s</th>
              <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">Brother&apos;s</th>
              <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">Sister&apos;s</th>
              <th className="px-4 py-2 sm:w-1/3 lg:w-1/6">
                Guardian&apos;s / Other&apos;s
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="sm:table-cell border px-4 py-2 font-bold">
                Profession
              </td>
              <td className="border px-4 py-2">
                {data?.financial.fatherProfession}
              </td>
              <td className="border px-4 py-2">
                {data?.financial.motherProfession}
              </td>
              <td className="border px-4 py-2">
                {data?.financial.brotherProfession}
              </td>
              <td className="border px-4 py-2">
                {data?.financial.sisterProfession}
              </td>
              <td className="border px-4 py-2">
                {data?.financial.guardianProfession}
              </td>
            </tr>
            <tr>
              <td className="sm:table-cell border px-4 py-2 font-bold">
                Salary/Pension
              </td>
              <td className="border px-4 py-2">{data?.financial.fatherSource}</td>
              <td className="border px-4 py-2">{data?.financial.motherSource}</td>
              <td className="border px-4 py-2">{data?.financial.brotherSource}</td>
              <td className="border px-4 py-2">{data?.financial.sisterSource}</td>
              <td className="border px-4 py-2">{data?.financial.guardianSource}</td>
            </tr>
            <tr>
              <td className="sm:table-cell border px-4 py-2 font-bold">
                Per Annum Income from all Sources
              </td>
              <td className="border px-4 py-2">{data?.financial.fatherIncome}</td>
              <td className="border px-4 py-2">{data?.financial.motherIncome}</td>
              <td className="border px-4 py-2">{data?.financial.brotherIncome}</td>
              <td className="border px-4 py-2">{data?.financial.sisterIncome}</td>
              <td className="border px-4 py-2">{data?.financial.guardianIncome}</td>
            </tr>
            {/* Rs. {data?.finacial?.totalIncome}/- */}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr className="justify-center">
              <td className="sm:table-cell border w-4/12 px-4 py-2 text-xl font-bold">
                Total Income Per Annum
              </td>
              <td className="border px-4 text-center py-2 w-10/12 bg-white font-semibold text-2xl">
                Rs. {data?.financial?.totalIncome}/-
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* source of income ends */}

      <div className="flex flex-col sm:flex-row gap-x-3 items-start mx-4">
  <p className="text-xl font-bold mb-2">
    Sibling(s) availing SEEF Scholarship?{" "}
    <span className="font-semibold underline ml-20">
      {data?.siblingsScholarship?.siblingsReceivingScholarship === "yes"
        ? "Yes"
        : "No"}
    </span>
  </p>
</div>

{data?.siblingsScholarship?.siblingsReceivingScholarship === "yes" && (
  <div className="mx-4 mt-4">
    <p className="mb-2 text-lg font-semibold">
      Details of Sibling Availing SEEF Scholarship:
    </p>
    <table className="border-collapse w-full border border-gray-300">
      <thead>
        <tr>
          <th className="border border-gray-400 py-2 w-3/12 px-4">Name</th>
          <th className="border border-gray-400 w-3/12 py-2 px-4">Relationship</th>
          <th className="border border-gray-400 py-2 px-4">University</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="border border-gray-400 bg-white py-2 w-3/12 px-4">
            {data?.siblingsScholarship.name || 'N/A'}
          </td>
          <td className="border border-gray-400 bg-white py-2 w-3/12 px-4">
            {data?.siblingsScholarship?.relationship || 'N/A'}
          </td>
          <td className="border border-gray-400 bg-white py-2 px-4">
            {data?.siblingsScholarship.university || 'N/A'}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)}

    </>
  );
};

export default Preview;
