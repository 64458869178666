import React, { useState, useEffect, useRef } from "react";
import Table from "./Table";
import AuthUser from "../../../Loji/loji";

const Dashboard = () => {
  const [totalApplications, setTotalApplications] = useState(0);
  const [maleApplications, setMaleApplications] = useState(0);
  const [femaleApplications, setFemaleApplications] = useState(0);

  const { http } = AuthUser();
  const SEEF_DASHBOARD = "counts";

  const prevTotalRef = useRef(totalApplications);
  const prevMaleRef = useRef(maleApplications);
  const prevFemaleRef = useRef(femaleApplications);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await http.get(SEEF_DASHBOARD);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const data = response.data;
        animateCount(prevTotalRef.current, data.totalApplications, setTotalApplications);
        animateCount(prevMaleRef.current, data.maleApplications, setMaleApplications);
        animateCount(prevFemaleRef.current, data.femaleApplications, setFemaleApplications);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error state if needed
      }
    };

    // Fetch counts immediately on mount
    fetchCounts();

    // Set interval to fetch counts every 90 seconds
    const intervalId = setInterval(fetchCounts, 90000); // 60000 ms = 1 minute

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    prevTotalRef.current = totalApplications;
    prevMaleRef.current = maleApplications;
    prevFemaleRef.current = femaleApplications;
  }, [totalApplications, maleApplications, femaleApplications]);

  const animateCount = (start, end, setter) => {
    const duration = 1000;
    const increment = (end - start) / (duration / 16);

    let current = start;
    const step = () => {
      current += increment;
      if ((increment > 0 && current >= end) || (increment < 0 && current <= end)) {
        setter(end);
      } else {
        setter(Math.floor(current));
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  };

  return (
    <div className="h-screen p-4 py-8 md:py-12 sm:py-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div className="bg-blue-500 text-white p-4 h-32 rounded shadow-md">
          <h3 className="text-lg font-semibold">Total Application Submitted</h3>
          <p className="text-2xl">{totalApplications}</p>
        </div>
        <div className="bg-green-500 text-white p-4 h-32 rounded shadow-md">
          <h3 className="text-lg font-semibold">Male</h3>
          <p className="text-2xl">{maleApplications}</p>
        </div>
        <div className="bg-pink-500 text-white p-4 h-32 rounded shadow-md">
          <h3 className="text-lg font-semibold">Female</h3>
          <p className="text-2xl">{femaleApplications}</p>
        </div>
      </div>
      <div>
        <Table />
      </div>
    </div>
  );
};

export default Dashboard;
