import React, { useState } from "react";
import AuthUser from "../Loji/loji"; // Adjust the path based on your actual file structure

const FamilyDetails = ({ formData, setFormData, nextStep, prevStep }) => {
  const [errors, setErrors] = useState({});
  const [familyMembers, setFamilyMembers] = useState(formData.familyMembers || []);
  const { http } = AuthUser();
  const SEEF_FAMILY = "family-members";
  const [newMember, setNewMember] = useState({
    name: "",
    age: "",
    relationship: "",
    maritalStatus: "",
    occupation: "",
    organization: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { cnicStudent } = formData; // Assuming cnicStudent is part of formData

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate age to input only numbers
    if (name === "age" && !/^\d*$/.test(value)) {
      return;
    }

    setNewMember({ ...newMember, [name]: value });
  };

  const validateMember = (member) => {
    const errors = {};
    if (!member.name) errors.name = "Name is required";
    if (!member.age || !/^\d*$/.test(member.age)) errors.age = "Age in Years is required";
    if (!member.relationship) errors.relationship = "Relationship is required";
    if (!member.maritalStatus) errors.maritalStatus = "Marital Status is required";
    if (!member.occupation) errors.occupation = "Occupation is required";
    if (!member.organization) errors.organization = "Organization is required";
    return errors;
  };

  const handleAddMember = () => {
    const memberErrors = validateMember(newMember);
    if (Object.keys(memberErrors).length === 0) {
      setFamilyMembers([...familyMembers, newMember]);
      setFormData({ ...formData, familyMembers: [...familyMembers, newMember] });
      setNewMember({
        name: "",
        age: "",
        relationship: "",
        maritalStatus: "",
        occupation: "",
        organization: "",
      });
      setErrors({});
    } else {
      setErrors(memberErrors);
    }
  };

  const handleDeleteMember = (index) => {
    const updatedMembers = [...familyMembers];
    updatedMembers.splice(index, 1);
    setFamilyMembers(updatedMembers);
    setFormData({ ...formData, familyMembers: updatedMembers });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate at least one family member is added
    if (familyMembers.length === 0) {
      setErrors({ familyMembers: "At least one family member is required" });
      return;
    }

    // Validate that at least one member is a "Father" or "Guardian"
    const hasRequiredRelationship = familyMembers.some(
      (member) => member.relationship === "Father" || member.relationship === "Guardian"
    );

    if (!hasRequiredRelationship) {
      setErrors({
        familyMembers: "At least one member must be a Father or Guardian",
      });
      return;
    }

    // Prepare data to be sent to the backend
    const familyData = familyMembers.map((member) => ({
      ...member,
      personal_id: formData.personal_id, // Assuming cnicStudent is the personal_id for family members
    }));

    setIsSubmitting(true);
    // try {
    //   // Send data to the backend
    //   const response = await http.post(SEEF_FAMILY, familyData);

    //   // Check response for success or specific conditions
    //   if (response.data.success) {
    //     console.log("Family members saved:", response.data.data);

    //     // Proceed to the next step
    //     setFormData({ ...formData, familyMembers });
        setIsSubmitting(false);
        nextStep();
    //   } else if (response.data.message === 'record_exist') {
    //     // If record_exist, log a message and proceed to the next step
    //     console.log("Family members already exist:", response.data.message);
    //     setFormData({ ...formData, familyMembers });
    //     setIsSubmitting(false);
    //     nextStep();
    //   } else {
    //     console.error("Failed to save family members:", response.data.message);
    //     setErrors({ familyMembers: "Failed to save family members" });
    //     setIsSubmitting(false);
    //   }
    // } catch (error) {
    //   console.error("Failed to save family members:", error.response.data);
    //   setErrors({ familyMembers: "Failed to save family members" });
    //   setIsSubmitting(false);
    // }
  };

  return (
    <form onSubmit={handleSubmit} className="mx-auto">
      <h2 className="text-2xl font-bold mb-6">Family Details</h2>

      {/* Add Family Member Form */}
      <div className="mb-6 max-w-screen-sm mx-auto">
        <h3 className="text-lg font-bold mb-4">Add Family Member</h3>
        <div className="flex flex-col mb-4">
          <select
            name="relationship"
            value={newMember.relationship}
            onChange={handleChange}
            className={`flex w-full px-4 py-2 mb-2 border rounded-md ${
              errors.relationship ? "border-red-500" : ""
            }`}
          >
            <option value="">Select Relationship</option>
            <option value="Father">Father</option>
            <option value="Mother">Mother</option>
            <option value="Brother">Brother</option>
            <option value="Sister">Sister</option>
            <option value="Guardian">Guardian</option>
          </select>
          {errors.relationship && (
            <p className="text-red-500 text-sm">{errors.relationship}</p>
          )}
          <input
            type="text"
            name="name"
            value={newMember.name}
            onChange={handleChange}
            placeholder="Name"
            className={`flex w-full px-4 py-2 mb-2 border rounded-md ${
              errors.name ? "border-red-500" : ""
            }`}
          />
          {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
          <input
            type="text"
            name="age"
            value={newMember.age}
            onChange={handleChange}
            placeholder="Age in Years"
            maxLength={3} // Maximum of 3 characters
            className={`flex w-full px-4 py-2 mb-2 border rounded-md ${
              errors.age ? "border-red-500" : ""
            }`}
          />
          {errors.age && <p className="text-red-500 text-sm">{errors.age}</p>}

          <select
            name="maritalStatus"
            value={newMember.maritalStatus}
            onChange={handleChange}
            className={`flex w-full px-4 py-2 mb-2 border rounded-md ${
              errors.maritalStatus ? "border-red-500" : ""
            }`}
          >
            <option value="">Select Marital Status</option>
            <option value="Married">Married</option>
            <option value="Single">Single</option>
            <option value="Divorced">Divorced</option>
            <option value="Separated">Separated</option>
            <option value="Widowed">Widowed</option>
          </select>
          {errors.maritalStatus && (
            <p className="text-red-500 text-sm">{errors.maritalStatus}</p>
          )}

          <input
            type="text"
            name="occupation"
            value={newMember.occupation}
            onChange={handleChange}
            placeholder="Occupation"
            className={`flex w-full px-4 py-2 mb-2 border rounded-md ${
              errors.occupation ? "border-red-500" : ""
            }`}
          />
          {errors.occupation && (
            <p className="text-red-500 text-sm">{errors.occupation}</p>
          )}
          <input
            type="text"
            name="organization"
            value={newMember.organization}
            onChange={handleChange}
            placeholder="Organization"
            className={`flex w-full px-4 py-2 mb-2 border rounded-md ${
              errors.organization ? "border-red-500" : ""
            }`}
          />
          {errors.organization && (
            <p className="text-red-500 text-sm">{errors.organization}</p>
          )}
          <button
            type="button"
            onClick={handleAddMember}
            className="w-full flex justify-center px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            Add Member
          </button>
        </div>
      </div>

      {/* Display Family Members */}
      <div className="mb-6">
        <h3 className="text-lg font-bold mb-4">Family Members</h3>
        {errors.familyMembers && (
          <p className="text-red-500 text-sm">{errors.familyMembers}</p>
        )}
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-200">
            <thead>
              <tr className="bg-gray-100 border-b border-gray-200">
                <th className="px-6 py-3 border-r border-gray-200">Name</th>
                <th className="px-6 py-3 border-r border-gray-200">Age (Years)</th>
                <th className="px-6 py-3 border-r border-gray-200">Relationship</th>
                <th className="px-6 py-3 border-r border-gray-200">Marital Status</th>
                <th className="px-6 py-3 border-r border-gray-200">Occupation</th>
                <th className="px-6 py-3 border-r border-gray-200">Organization</th>
                <th className="px-6 py-3">Actions</th>
              </tr>
            </thead>
            <tbody>
              {familyMembers.map((member, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="px-6 py-4 whitespace-nowrap">{member.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{member.age}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{member.relationship}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{member.maritalStatus}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{member.occupation}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{member.organization}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => handleDeleteMember(index)}
                      className="px-3 py-1 bg-red-600 text-white rounded-md"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={prevStep}
          className="px-6 py-2 bg-gray-600 text-white rounded-md"
        >
          Back
        </button>
        <button type="submit" className="px-6 py-2 bg-blue-600 text-white rounded-md" disabled={isSubmitting}>
          {isSubmitting ? "Please wait..." : "Next"}
        </button>
      </div>
    </form>
  );
};

export default FamilyDetails;
