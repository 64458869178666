import React from "react";

const Documents = () => {
  return (
    <>
      <div className=" mx-3 sm:mx-6">
        <h1 className="text-left font-bold text-2xl">4. REQUIRED DOCUMENTS</h1>

        <hr className="border-t-2 border-gray-700 my-5 rounded-none" />
      </div>
      <div>
        <div className="flex flex-col lg:flex-row my-4 gap-3">
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-id" className="hidden" />
            <label htmlFor="student-id" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              1. Copy of Student Identity Card of the University / Institute.
            </div>
          </div>
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-cnic" className="hidden" />
            <label htmlFor="student-cnic" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className=" flex-1 select-none text-justify">
              2. Copy of Student CNIC/Copy of CNIC of Father / Guardian.
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row my-4 gap-3">
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-id" className="hidden" />
            <label htmlFor="student-id" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              3. Copy of Fee Receipt of the University / Institute (Last Paid)
            </div>
          </div>
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-cnic" className="hidden" />
            <label htmlFor="student-cnic" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              4. Copy of Certificate of Domicile of the student.
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row my-4 gap-3">
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-id" className="hidden" />
            <label htmlFor="student-id" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              5. Copy of Permanent Residence Certificate of Student.
            </div>
          </div>
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-cnic" className="hidden" />
            <label htmlFor="student-cnic" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              6. Coy of Domicile Certificate of Father / Mother / Guardian.
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row my-4 gap-3">
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-id" className="hidden" />
            <label htmlFor="student-id" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              7. Copy of Academic Certificates including MarkSheets of previous
              year
            </div>
          </div>
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-cnic" className="hidden" />
            <label htmlFor="student-cnic" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              8. Copy of House documents being the evidence of Residence/Rent
              document.
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row my-4 gap-3">
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-id" className="hidden" />
            <label htmlFor="student-id" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              9. Original Salary Slip of Father / Guardian if employed in
              Government / Semi Government / Private organization.
            </div>
          </div>
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-cnic" className="hidden" />
            <label htmlFor="student-cnic" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              10. Original Income Certificate issued by Mukhtarkar Revenue of
              concerned District in case Father / Guardian is a non-salary
              person i.e. Business man / Landlord etc., along with Bank
              Statement of last six (6) months of personal and Business account.
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row my-4 gap-3">
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-id" className="hidden" />
            <label htmlFor="student-id" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 select-none text-justify">
              11. Original Copy of Income Assessment / Return of Income duly
              issued by Income Tax Department, Government of Pakistan.
            </div>
          </div>
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-cnic" className="hidden" />
            <label htmlFor="student-cnic" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 text-justify select-none">
              12. Copies of Utility Bills: Electricity / Gas / Telephone (Last
              paid).
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row my-4 gap-3">
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-id" className="hidden" />
            <label htmlFor="student-id" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 text-justify select-none">
              13. Original Affidavit on Rs. 50/= Stamp paper.
            </div>
          </div>
          <div className="flex lg:w-1/2 mb-4 lg:mb-0">
            <input type="checkbox" id="student-cnic" className="hidden" />
            <label htmlFor="student-cnic" className="flex cursor-pointer">
              <div className="w-6 h-6 border border-gray-400 rounded mr-2"></div>
            </label>
            <div className="flex-1 text-justify select-none">
              14. Incomplete applications will not be considered.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Documents;
