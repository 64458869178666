import React, { useState } from "react";
import AuthUser from "../Loji/loji"; // Adjust the path based on your actual file structure

const PropertyDetails = ({ formData, setFormData, nextStep, prevStep }) => {
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { http } = AuthUser();
  const SEEF_PROPERTY = "property";

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Validate input to allow only numbers for specific fields
    const numberFields = ["propertyValue", "propertyArea", "assetArea", "assetValue"];
    if (numberFields.includes(name) && !/^\d*$/.test(value)) {
      return;
    }

    // Handle clearing asset-related fields if assetType is notApplicable
    if (name === "assetType" && value === "notApplicable") {
      setFormData({
        ...formData,
        assetType: "notApplicable",
        assetAddress: "",
        assetArea: "",
        assetUnit: "",
        assetValue: ""
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.propertyCategory) newErrors.propertyCategory = "Property Category is required";
    if (!formData.propertyAddress) newErrors.propertyAddress = "Property address is required";
    if (!formData.propertyType) newErrors.propertyType = "Property type is required";
    if (!formData.propertyArea) newErrors.propertyArea = "Property Area is required";
    if (!formData.propertyUnit) newErrors.propertyUnit = "Unit is required";
    if (formData.propertyCategory === "Owned" && !formData.propertyValue) {
      newErrors.propertyValue = "Property Value is required";
    }
    if (formData.assetType !== "notApplicable") {
      if (!formData.assetAddress) newErrors.assetAddress = "Asset address is required";
      if (!formData.assetArea) newErrors.assetArea = "Asset Area is required";
      if (!formData.assetUnit) newErrors.assetUnit = "Asset Unit is required";
      if (!formData.assetValue) newErrors.assetValue = "Asset Value is required";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validate();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setSubmitting(true);

        // Assuming you have an API endpoint to send formData
        // const response = await http.post(SEEF_PROPERTY, formData);
        // console.log("Property details saved:", response.data);

        // Reset form errors and move to the next step
        setErrors({});
        nextStep();
      } catch (error) {
        console.error("Failed to save property details:", error.response.data);
        setErrors({ propertyDetails: "Failed to save property details" });
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="text-2xl font-bold mb-6">Residential Details</h2>
      <div className="mb-6 max-w-screen-sm mx-auto">
        <div className="mb-4">
          <label className="block text-gray-700">Property Category</label>
          <select
            name="propertyCategory"
            value={formData.propertyCategory || ""}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          >
            <option value="">Select Category</option>
            <option value="rented">Rented</option>
            <option value="Owned">Owned</option>
          </select>
          {errors.propertyCategory && <p className="text-red-500 text-sm">{errors.propertyCategory}</p>}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Property Type</label>
          <select
            name="propertyType"
            value={formData.propertyType || ""}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          >
            <option value="">Select Property Type</option>
            <option value="bungalow">Bungalow</option>
            <option value="apartment">Apartment</option>
          </select>
          {errors.propertyType && <p className="text-red-500 text-sm">{errors.propertyType}</p>}
        </div>
        
        <div className="mb-4">
          <label className="block text-gray-700">Residential Address / Exact Location</label>
          <input
            type="text"
            name="propertyAddress"
            value={formData.propertyAddress || ""}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          />
          {errors.propertyAddress && <p className="text-red-500 text-sm">{errors.propertyAddress}</p>}
        </div>

        <div className="flex space-x-4 w-full justify-between">
          <div className="mb-4 w-2/3">
            <label className="block text-gray-700">Area of the Property</label>
            <input
              type="text"
              name="propertyArea"
              value={formData.propertyArea || ""}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md"
            />
            {errors.propertyArea && <p className="text-red-500 text-sm">{errors.propertyArea}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Area Units</label>
            <select
              name="propertyUnit"
              value={formData.propertyUnit || ""}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md"
            >
              <option value="">Select Units</option>
              <option value="sqyds">Sq. Yards</option>
              <option value="sqft">Sq. Feet</option>
              <option value="acres">Acres</option>
            </select>
            {errors.propertyUnit && <p className="text-red-500 text-sm">{errors.propertyUnit}</p>}
          </div>
        </div>

        {formData.propertyCategory === "Owned" && (
          <div className="mb-4">
            <label className="block text-gray-700">Property Value in PKR</label>
            <input
              type="text"
              name="propertyValue"
              value={formData.propertyValue || ""}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md"
            />
            {errors.propertyValue && <p className="text-red-500 text-sm">{errors.propertyValue}</p>}
          </div>
        )}
      </div>

      <h2 className="text-2xl font-bold mb-6">Immovable Assets Details</h2>
      <div className="mb-6 max-w-screen-sm mx-auto">
        <div className="mb-4">
          <label className="block text-gray-700">Immovable Asset Type</label>
          <select
            name="assetType"
            value={formData.assetType || ""}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md"
          >
            <option value="">Select Property Type</option>
            <option value="notApplicable">Not Applicable</option>
            <option value="plot">Plot (Commercial)</option>
            <option value="landCommercial">Land (Commercial)</option>
            <option value="landAgricultural">Land (Agricultural)</option>
            <option value="landAgriboth">Land (Both)</option>
          </select>
        </div>

        {formData.assetType !== "notApplicable" && (
          <>
            <div className="mb-4">
              <label className="block text-gray-700">Immovable Asset Address / Exact Location</label>
              <input
                type="text"
                name="assetAddress"
                value={formData.assetAddress || ""}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
              {errors.assetAddress && <p className="text-red-500 text-sm">{errors.assetAddress}</p>}
            </div>

            <div className="flex space-x-4 w-full justify-between">
              <div className="mb-4 w-2/3">
                <label className="block text-gray-700">Area of the Immovable</label>
                <input
                  type="text"
                  name="assetArea"
                  value={formData.assetArea || ""}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md"
                />
                {errors.assetArea && <p className="text-red-500 text-sm">{errors.assetArea}</p>}
              </div>

              <div className="mb-4 w-1/3">
                <label className="block text-gray-700">Area Units</label>
                <select
                  name="assetUnit"
                  value={formData.assetUnit || ""}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-md"
                >
                  <option value="">Select Units</option>
                  <option value="sqyds">Sq. Yards</option>
                  <option value="sqft">Sq. Feet</option>
                  <option value="acres">Acres</option>
                </select>
                {errors.assetUnit && <p className="text-red-500 text-sm">{errors.assetUnit}</p>}
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Asset Value in PKR</label>
              <input
                type="text"
                name="assetValue"
                value={formData.assetValue || ""}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
              {errors.assetValue && <p className="text-red-500 text-sm">{errors.assetValue}</p>}
            </div>
          </>
        )}
      </div>

      <div className="flex justify-between">
        <button type="button" onClick={prevStep} className="px-6 py-2 bg-gray-600 text-white rounded-md">
          Back
        </button>
        <button
          type="submit"
          disabled={submitting}
          className={`bg-blue-500 text-white px-4 py-2 rounded-md ${submitting ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          {submitting ? "Please wait..." : "Next"}
        </button>
      </div>
    </form>
  );
};

export default PropertyDetails;
