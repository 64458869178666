import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import "./AgGridTable.css";
import AuthUser from "../../../Loji/loji";
import * as XLSX from "xlsx";
import Modal from "react-modal"; // Import react-modal
import Preview from "./Preview";
import { ScaleLoader } from "react-spinners"; // Import the loader component from your chosen library
import customButtonComponent from "./customButtonComponent";
// import PreviewForm from "./Preview";
// Set app element for accessibility (required for react-modal)
Modal.setAppElement("#root");

const AgGridTable = () => {
  const [rowData, setRowData] = useState([]);
  const [paginationPageSize, setPaginationPageSize] = useState(50);
  const [previewData, setPreviewData] = useState(null); // State to hold data for preview
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // State to control modal visibility
  const [loading, setLoading] = useState(true);
  const { http } = AuthUser();
  const SEEF_Table = "tables";
  const Preview_Data = "check-cnic";

  useEffect(() => {
    const fetchTable = async () => {
      try {
        const response = await http.get(SEEF_Table);
        if (response.status !== 200) {
          throw new Error("Failed to fetch data");
        }
        const data = response.data;
        setRowData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    // Fetch data initially
    fetchTable();

    // Fetch data every 3 minutes
    const interval = setInterval(() => {
      fetchTable();
    }, 3 * 60 * 1000); // 3 minutes

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);

  const columnDefs = [
    {
      headerName: "Sr. No.",
      valueGetter: "node.rowIndex + 1",
      sortable: false,
      filter: false,
      width: 70, // Set the width for this column
    },
    {
      headerName: "Univ. Reg. No.",
      field: "registrationNumber",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Student Name",
      field: "studentName",
      sortable: true,
      filter: true,
    },
    { headerName: "Gender", field: "gender", sortable: true, filter: true, width: 100, 
      // Set the width for this column 
       },
    {
      headerName: "Student CNIC",
      field: "cnicStudent",
      sortable: true,
      filter: true,
      width: 150, // Set the width for this column
    },
    { headerName: "Email", field: "email", sortable: true, filter: true },
    {
      headerName: "Student Mobile",
      field: "mobileStudent",
      sortable: true,
      filter: true,
      width: 140, // Set the width for this column
    },
    {
      headerName: "Father / Guardian Name",
      field: "fatherName",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Discipline",
      field: "discipline",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Year of Admission",
      field: "yearOfAdmission",
      sortable: true,
      filter: true,
      width: 70, // Set the width for this column
    },
    {
      headerName: "CGPA / PERCENTAGE",
      field: "result",
      sortable: true,
      filter: true,
      width: 145, // Set the width for this column
    },
    { headerName: "Domicile", field: "district", sortable: true, filter: true },
    {
      headerName: "Father Guardian Occupation",
      field: "fatherProfession",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Total Income Annually",
      field: "totalIncome",
      sortable: true,
      filter: true,
      width: 100, // Set the width for this column
    },
    {
      headerName: "University",
      field: "universityName",
      sortable: true,
      filter: true,
    },

    {
      headerName: "Actions",
      field: "actions",
      width: 135, // Set the width for this column
      cellRenderer: (params) => (
        <button
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-1 text-sm rounded-md shadow-sm transition duration-300 ease-in-out transform hover:scale-105"
          onClick={() => handlePreview(params.data)}
        >
          View Form
        </button>
      ),
      sortable: false,
      filter: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  const handlePageSizeChange = (event) => {
    const value = event.target.value;
    if (value === "all") {
      setPaginationPageSize(rowData.length);
    } else {
      setPaginationPageSize(Number(value));
    }
  };

  const handleDownload = async (rowData) => {
    // Example: Downloading a file related to rowData
    try {
      const response = await axios.get(`/download/${rowData.id}`, {
        responseType: "blob",
      });
      // Process the downloaded file
      console.log(response);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleEdit = async (rowData) => {
    // Example: Redirecting to edit page for rowData
    console.log("Editing:", rowData);
    // Implement navigation or modal opening for editing
  };

  const handleDelete = async (rowData) => {
    // Example: Deleting rowData from backend
    try {
      const response = await axios.delete(`/delete/${rowData.id}`);
      console.log("Delete response:", response);
      // Remove the deleted row from rowData state
      setRowData((prevData) =>
        prevData.filter((item) => item.id !== rowData.id)
      );
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  // const handlePreview = (data) => {
  //   setPreviewData(data);
  //   // console.log("Preview data:", previewData); // Check what data is being passed
  //   setIsPreviewOpen(true);
  // };

  const handlePreview = async (rowData) => {
    setLoading(true); // Set loading to true before fetching data
    try {
      // Update the endpoint to a general one that does not include cnicStudent in the URL
      const endpoint = `${Preview_Data}`;
  
      // Send cnicStudent in the request body
      const res = await http.post(endpoint, {
        cnicStudent: rowData.cnicStudent,
      });
  
      if (res.status === 200) {
        setPreviewData(res.data.data);
        setIsPreviewOpen(true);
      } else {
        console.error("Failed to fetch preview data");
      }
    } catch (error) {
      console.error("Error fetching preview data:", error);
    } finally {
      setLoading(false); // Set loading to false once data has been fetched or an error occurs
    }
  };

  const closePreview = () => {
    setIsPreviewOpen(false);
    setPreviewData(null);
  };

  const generateExcel = () => {
    const universityGroups = rowData.reduce((acc, curr) => {
      const { universityName } = curr;
      if (!acc[universityName]) {
        acc[universityName] = [];
      }
      acc[universityName].push(curr);
      return acc;
    }, {});

    const workbook = XLSX.utils.book_new();

    Object.keys(universityGroups).forEach((universityName, index) => {
      const sheetName = `University ${index + 1}`;
      const worksheetData = universityGroups[universityName].map(
        (row, idx) => ({
          ...row, // Include all fields from rowData
          "S. No.": idx + 1,
          "UNIV REG. NO.": row.registrationNumber,
          "STUDENT NAME": row.studentName,
          GENDER: row.gender,
          "FATHER / GUARDIAN NAME": row.fatherName,
          DISCIPLINE: row.discipline,
          "YEAR OF ADMISSION": row.yearOfAdmission,
          "CGPA / PERCENTAGE": row.result,
          DOMICILE: row.district,
          "FATHER / GUARDIAN OCCUPATION": row.fatherProfession,
          "TOTAL INCOME PER ANNUM": row.totalIncome,
          REMARKS: row.remarks, // Assuming 'remarks' field exists in your rowData
        })
      );

      const headerRow1 = ["COLLEGE EDUCATION DEPARTMENT GOVERNMENT OF SINDH"];
      const headerRow2 = [
        "SINDH EDUCATIONAL ENDOWMENT FUND TRUCT SCHOLARSHIP FOR THE ACADEMIC YEAR 2023-24",
      ];
      const headerRow3 = [`NAME OF UNIVERSITY: ${universityName}`];
      const columnHeaders = [
        "S. No.",
        "UNIV REG. NO.",
        "STUDENT NAME",
        "GENDER",
        "FATHER / GUARDIAN NAME",
        "DISCIPLINE",
        "YEAR OF ADMISSION",
        "CGPA / PERCENTAGE",
        "DOMICILE",
        "FATHER / GUARDIAN OCCUPATION",
        "TOTAL INCOME PER ANNUM",
        "REMARKS",
        "", // Additional header 1
        "", // Additional header 2
      ];

      const dataRows = worksheetData.map((row) => [
        row["S. No."],
        row["UNIV REG. NO."],
        row["STUDENT NAME"],
        row["GENDER"],
        row["FATHER / GUARDIAN NAME"],
        row["DISCIPLINE"],
        row["YEAR OF ADMISSION"],
        row["CGPA / PERCENTAGE"],
        row["DOMICILE"],
        row["FATHER / GUARDIAN OCCUPATION"],
        row["TOTAL INCOME PER ANNUM"],
        row["REMARKS"],
        "", // Placeholder for additional header 1 data
        "", // Placeholder for additional header 2 data
      ]);

      const sheetData = [
        headerRow1,
        headerRow2,
        headerRow3,
        columnHeaders,
        ...dataRows.map(Object.values),
      ];

      const sheet = XLSX.utils.aoa_to_sheet(sheetData);

      // Merge header cells across all columns
      const mergeRange = {
        s: { r: 0, c: 0 },
        e: { r: 0, c: sheetData[1].length - 1 }, // Use the length of the columnHeaders array
      };
      sheet["!merges"] = [mergeRange];

      XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
    });

    XLSX.writeFile(workbook, "university_data.xlsx");
  };

  return (
    <div className="container mx-auto p-4">
      {loading ? (
        <div className="text-center">
          <ScaleLoader
            color="#4A90E2"
            loading={loading}
            height={35}
            width={4}
            radius={2}
          />
          <p className="text-lg mt-4">Please wait... Data is being loaded.</p>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold underline">
              Scholarship Form Details
            </h2>
            <button
              onClick={generateExcel}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Download xlsx
            </button>
          </div>
          <div className="ag-grid-container ag-theme-alpine">
            <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              pagination={true}
              paginationPageSize={paginationPageSize}
              defaultColDef={defaultColDef}
              domLayout="normal"
            />
            {/* <Preview isOpen={isPreviewOpen} onClose={closePreview} data={previewData} /> */}
          </div>
          {/* Modal for preview */}
          <Modal
            isOpen={isPreviewOpen}
            onRequestClose={closePreview}
            contentLabel="Preview Modal"
            className="Modal"
            overlayClassName="Overlay"
          >
            <Preview data={previewData} />
            <button onClick={closePreview} className="modal-close-btn">
              Close
            </button>
          </Modal>
        </>
      )}
    </div>
  );
};

export default AgGridTable;
