import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import AuthUser from "../Loji/loji"; // Adjust the path based on your actual file structure

const AcademicInformation = ({ formData, setFormData, nextStep, prevStep }) => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSocialSciencesPopup, setShowSocialSciencesPopup] = useState(false);
  const [eligibleForScholarship, setEligibleForScholarship] = useState(true);
  const [universities, setUniversities] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const { http } = AuthUser();
  const SEEF_UNIVERSITIES = "universities";
  const SEEF_DISCIPLINES = "disciplines";
  const SEFF_ACADEMIC = 'academics';
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  useEffect(() => {
    // Fetch universities and disciplines from backend on component mount
    const fetchData = async () => {
      try {
        // Fetching universities
        const universitiesResponse = await http.get(SEEF_UNIVERSITIES);
        setUniversities(universitiesResponse.data);

        // Fetching disciplines
        const disciplinesResponse = await http.get(SEEF_DISCIPLINES);
        setDisciplines(disciplinesResponse.data);
      } catch (error) {
        console.error("Error fetching universities or disciplines:", error);
        // Handle error, set default data or show error message
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;

    if (name === "gpa" || name === "percentage") {
      updatedValue = value.replace(/[^\d.]/g, "");
      const decimalIndex = updatedValue.indexOf(".");
      if (decimalIndex !== -1) {
        updatedValue = updatedValue.slice(0, decimalIndex + 3);
      } else {
        updatedValue = updatedValue.slice(0, 3);
      }
    }

    if (name === "gradeSystem") {
      if (value === "FirstYearStudent") {
        setFormData({ ...formData, gpa: "", percentage: "", gradeSystem: value });
      } else if (value === "GPA") {
        setFormData({ ...formData, gpa: "", percentage: "", gradeSystem: value });
      } else if (value === "Percentage") {
        setFormData({ ...formData, gpa: "", percentage: "", gradeSystem: value });
      }
    } else {
      setFormData({ ...formData, [name]: updatedValue });
    }
  };

  // const handleCnicStudentChange = (e) => {
  //   let formattedCnicStudent = e.target.value.replace(/\D/g, "");
  //   formattedCnicStudent = formattedCnicStudent.slice(0, 13);

  //   if (formattedCnicStudent.length > 5 && formattedCnicStudent.length < 13) {
  //     formattedCnicStudent = formattedCnicStudent.replace(
  //       /^(\d{5})(\d{7})?/,
  //       "$1-$2"
  //     );
  //   } else if (formattedCnicStudent.length === 13) {
  //     formattedCnicStudent = formattedCnicStudent.replace(
  //       /^(\d{5})(\d{7})(\d{1})/,
  //       "$1-$2-$3"
  //     );
  //   }

  //   setFormData({
  //     ...formData,
  //     personal_id: formattedCnicStudent,
  //   });

  //   if (errors.cnicStudent) {
  //     setErrors((prevErrors) => ({ ...prevErrors, cnicStudent: "" }));
  //   }
  // };

  const validate = () => {
    const newErrors = {};

    if (formData.applyAs === "Fresh Candidate") {
      // if (!formData.personal_id) newErrors.personal_id = "CNIC is required";
      // else if (!/^\d{5}-\d{7}-\d{1}$/.test(formData.personal_id))
      //   newErrors.personal_id = "CNIC must be in the format 42201-1234567-1";
      if (!formData.registrationNumber)
        newErrors.registrationNumber = "Registration Number is required";
      if (!formData.yearOfAdmission)
        newErrors.yearOfAdmission = "Year of Admission is required";
      if (!formData.universityName)
        newErrors.universityName = "University / Institute is required";
      if (!formData.gradeSystem)
        newErrors.gradeSystem = "Grade System is required";
      if (formData.gradeSystem === "GPA" && !formData.gpa)
        newErrors.gpa = "GPA is required";
      if (formData.gradeSystem === "Percentage" && !formData.percentage)
        newErrors.percentage = "Percentage is required";
    }
    if (!formData.discipline) newErrors.discipline = "Discipline is required";
    // if (!formData.district) newErrors.district = "District is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsSubmitting(true); // Set submitting state to true

    try {
      // Make the API call to submit the form data
      // const response = await http.post(SEFF_ACADEMIC, formData);

      // Handle successful response
      // console.log("Response from backend:", response.data);
      setIsSubmitting(false); // Set submitting state to false
      nextStep(); // Move to the next step or perform any other necessary action
    } catch (error) {
      // Handle API call errors
      console.error("Error while submitting data:", error);
      setIsSubmitting(false); // Set submitting state to false

      // Check if the error contains specific error messages
      if (error.response && error.response.data && error.response.data.errors) {
        setErrors(error.response.data.errors); // Set errors from API response
      } else {
        setErrors({
          general: "Something went wrong. Please try again later.",
        }); // Set a generic error message
      }
    }
  };

  const handleYearOfAdmissionChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, yearOfAdmission: value });
  };

  const handleDisciplineChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear universities list first
    setUniversities([]);

    // Check if the selected discipline is "NURSING"
    if (value === "NURSING") {
      // Set specific universities for Nursing
      setUniversities([
        "CHANDKA MEDICAL COLLEGE, LARKANA",
        "DOW UNIVERSITY OF HEALTH SCIENCES (DUHS), KARACHI",
        "GAMBAT INSTITUTE OF MEDICAL SCIENCES, KHAIRPUR",
        "GHULAM MUHAMMAD MEHER MEDICAL COLLEGE, SUKKUR",
        "JINNAH SINDH MEDICAL UNIVERSITY (JSMU), KARACHI",
        "KARACHI MEDICAL AND DENTAL COLLEGE, KARACHI",
        "KHAIRPUR MEDICAL COLLEGE, KHAIRPUR",
        "LIAQUAT UNIVERSITY OF MEDICAL & HEALTH SCIENCES (LUMHS), JAMSHORO",
        "PEOPLES UNIVERSITY OF MEDICAL & HEALTH SCIENCES FOR WOMEN, SHAHEED BENAZIRABAD",
        "SHAHEED BENAZIR BHUTTO MEDICAL UNIVERSITY, LIYARI, KARACHI",
        "THAR INSTITUTE OF NURSING AND ALLIED HEALTH SCIENCES"
      ]);
    } else {
      // Fetch universities from backend
      try {
        const universitiesResponse = await http.get(SEEF_UNIVERSITIES);
        setUniversities(universitiesResponse.data);
      } catch (error) {
        console.error("Error fetching universities:", error);
        // Handle error, set default data or show error message
      }
    }

    // Check if the selected discipline is "Social Sciences"
    if (value === "SOCIAL SCIENCE") {
      setShowSocialSciencesPopup(true);
    } else {
      setShowSocialSciencesPopup(false);
    }
  };

  const handleSocialSciencesPopupResponse = (response) => {
    setShowSocialSciencesPopup(false);
    if (response === "yes") {
      setEligibleForScholarship(true);
    } else {
      setEligibleForScholarship(false);
      window.alert("Sorry You Are not Eligible for Scholarship");
      // Navigate to seef.gos.pk if response is "no"
      window.open("https://seef.sindh.gov.pk/"); // Opens link in a new tab
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="text-2xl font-bold mb-6">Academic Information</h2>

      <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          Apply As
        </label>
        <select
          name="applyAs"
          value={formData.applyAs || ""}
          onChange={handleChange}
          className="w-full px-4 py-2 border rounded-md"
        >
          <option value="">Select Apply As</option>
          <option value="Fresh Candidate">Fresh Candidate</option>
        </select>
        {errors.applyAs && (
          <p className="text-red-500 text-sm">{errors.applyAs}</p>
        )}
      </div>

      {formData.applyAs === "Fresh Candidate" && (
        <>
        {/* <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          CNIC Student
        </label>
        <input
          type="text"
          name="personal_id"
          placeholder="42201-1234567-1"
          value={formData.personal_id || ""}
          onChange={handleCnicStudentChange}
          className="w-full px-4 py-2 border rounded-md"
        />
        {errors.personal_id && (
          <p className="text-red-500 text-sm">{errors.personal_id}</p>
        )}
      </div> */}
          <div className="mb-4">
            <label className="block text-lg font-semibold text-gray-700">
              University Registration Number
            </label>
            <input
              type="text"
              name="registrationNumber"
              value={formData.registrationNumber || ""}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md"
            />
            {errors.registrationNumber && (
              <p className="text-red-500 text-sm">
                {errors.registrationNumber}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-lg font-semibold text-gray-700">
              Year of Admission
            </label>
            <select
              name="yearOfAdmission"
              value={formData.yearOfAdmission || ""}
              onChange={handleYearOfAdmissionChange}
              className="w-full px-4 py-2 border rounded-md"
            >
              <option value="">Select Year of Admission</option>
              <option value="2023">2023-24</option>
              {/* <option value="2022">2022-23</option> */}
            </select>
            {errors.yearOfAdmission && (
              <p className="text-red-500 text-sm">{errors.yearOfAdmission}</p>
            )}
          </div>
          <div className="mb-4">
        <label className="block text-lg font-semibold text-gray-700">
          Discipline
        </label>
        <select
          name="discipline"
          value={formData.discipline || ""}
          onChange={handleDisciplineChange}
          className="w-full px-4 py-2 border rounded-md"
        >
          <option value="">Select Discipline</option>
          {disciplines.map((discipline) => (
            <option key={discipline} value={discipline}>
              {discipline}
            </option>
          ))}
        </select>
        {errors.discipline && (
          <p className="text-red-500 text-sm">{errors.discipline}</p>
        )}
      </div>
          <div className="mb-4">
            <label className="block text-lg font-semibold text-gray-700">
              University / Institute
            </label>
            <select
              name="universityName"
              value={formData.universityName || ""}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md"
            >
              <option value="">Select University / Institute</option>
              {universities.map((university) => (
                <option key={university} value={university}>
                  {university}
                </option>
              ))}
            </select>
            {errors.universityName && (
              <p className="text-red-500 text-sm">{errors.universityName}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-lg font-semibold text-gray-700">
              Grade System
            </label>
            <select
              name="gradeSystem"
              value={formData.gradeSystem || ""}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-md"
            >
              <option value="">Select Grade System</option>
              <option value="GPA">GPA</option>
              <option value="Percentage">Percentage</option>
              <option value="FirstYearStudent">First Year</option>
            </select>
            {errors.gradeSystem && (
              <p className="text-red-500 text-sm">{errors.gradeSystem}</p>
            )}
          </div>
          {formData.gradeSystem === "GPA" && (
            <div className="mb-4">
              <label className="block text-lg font-semibold text-gray-700">
                GPA
              </label>
              <input
                type="text"
                name="gpa"
                value={formData.gpa || ""}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
              {errors.gpa && (
                <p className="text-red-500 text-sm">{errors.gpa}</p>
              )}
            </div>
          )}
          {formData.gradeSystem === "Percentage" && (
            <div className="mb-4">
              <label className="block text-lg font-semibold text-gray-700">
                Percentage
              </label>
              <input
                type="text"
                name="percentage"
                value={formData.percentage || ""}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
              {errors.percentage && (
                <p className="text-red-500 text-sm">{errors.percentage}</p>
              )}
            </div>
          )}
        </>
      )}

      

    
      {showSocialSciencesPopup && (
        <div className="mb-4 bg-gray-100 p-4 rounded-md">
          <p className="text-gray-700">
            Are you a student of BS (Social Sciences) or Anyother subject of Social Sciences category?
          </p>
          <button
            type="button"
            className="px-4 py-2 bg-blue-500 text-white rounded-md"
            onClick={() => handleSocialSciencesPopupResponse("yes")}
          >
            Social Sciences
          </button>
          <button
            type="button"
            className="px-4 py-2 bg-blue-500 text-white rounded-md ml-2"
            onClick={() => handleSocialSciencesPopupResponse("no")}
          >
            Other Subject
          </button>
        </div>
      )}

      {errors.general && (
        <p className="text-red-500 text-sm mb-4">{errors.general}</p>
      )}

      <div className="flex justify-between">
        <button
          type="button"
          onClick={prevStep}
          className="px-4 py-2 bg-gray-500 text-white rounded-md"
        >
          Previous
        </button>
        <button
          type="submit"
          className={`px-4 py-2 ${isSubmitting ? "bg-gray-400" : "bg-blue-500"} text-white rounded-md`}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Next"}
        </button>
      </div>
    </form>
  );
};

export default AcademicInformation;
