import React from "react";
import Swal from "sweetalert2";
import axios from "axios";

const VerifyExistenceSwal = () => {
  const handleVerifyExistence = () => {
    Swal.fire({
      title: "Verify CNIC and Email",
      html: `<input type="text" id="cnic" class="swal2-input" placeholder="CNIC (e.g. 12345-6789123-4)">
             <input type="text" id="email" class="swal2-input" placeholder="Email (e.g. example@example.com)">`,
      showCancelButton: true,
      confirmButtonText: "Verify",
      cancelButtonText: "Cancel",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const cnicInput = document.getElementById("cnic");
        const emailInput = document.getElementById("email");
        const cnic = cnicInput.value;
        const email = emailInput.value;

        try {
          const response = await axios.post("/api/check-existence", {
            cnicStudent: cnic,
            email: email,
          });

          const data = response.data;
          if (data.exists.cnicStudent || data.exists.email) {
            Swal.fire(
              "Exists",
              `CNIC or Email already exists in the database`,
              "error"
            );
          } else {
            Swal.fire(
              "Doesn't Exist",
              `CNIC and Email do not exist in the database`,
              "success"
            );
          }
        } catch (error) {
          console.error("Error verifying CNIC and Email existence:", error);
          Swal.fire("Error", "Error verifying CNIC and Email existence", "error");
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  return (
    <div>
      <button
        onClick={handleVerifyExistence}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Verify CNIC and Email
      </button>
    </div>
  );
};

export default VerifyExistenceSwal;
