import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Admin from "../Admin/Admin";
function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const userCredentials = {
    "admin@seef.gos.pk": "password123",
    "secretary@ced.gos.pk": "secretary@ced2024",
    "addl.sect1@ced.gos.pk": "ced@2024.he1",
    "addl.sect2@ced.gos.pk": "ced@2024.he2",
    "ps.secretary@ced.gos.pk": "ced@2024.ps",
  };
  function handleSubmit(event) {
    event.preventDefault();

    if (userCredentials[email] === password) {
      setIsLoggedIn(true);
      setError(null);
      console.log("Login successful");
      navigate("/admin");
    } else {
      setIsLoggedIn(false);
      setError("Invalid email or password");
      console.log("Login failed");
    }  }

  function handleForgotPassword(event) {
    event.preventDefault();
    console.log("Forgot password");
  }

  if (isLoggedIn) {
    return <Admin />;
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center p-4 bg-gray-100">
      <header className="flex flex-col lg:flex-row justify-between items-center mb-4">
          <div className="w-20 mb-4 lg:mb-0 lg:mr-4">
            <img src="/logo.png" alt="Logo" className="w-full" />
          </div>
          <div className="text-center lg:ml-10">
            <h2 className="font-bold text-lg">GOVERNMENT OF SINDH</h2>
            <p className="text-base">COLLEGE EDUCATION DEPARTMENT</p>
            <p className="text-base">SINDH EDUCATIONAL ENDOWMENT FUND</p>
            <p className="text-base">REGISTERED VIDE TRUST DEED NO. 625/2020</p>
          </div>
        </header>
        <div className="max-w-md w-full p-6 bg-gray-300 rounded-lg shadow-lg mt-4">
        <h1 className="text-2xl font-bold mb-5 text-center">
          Log in to your account
        </h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded-md focus:outline-none focus:shadow-outline"
          >
            Log in
          </button>
        </form>
        {error && <p className="text-red-500 text-center mt-2">{error}</p>}
        <div className="text-center mt-2">
          <a
            href="#"
            className="text-blue-500 hover:text-blue-600"
            onClick={handleForgotPassword}
          >
            Forgot password?
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
