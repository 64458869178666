import React, { useState, useEffect } from "react";
import axios from "axios";
import AuthUser from "../Loji/loji"; // Adjust the path based on your actual file structure

const SiblingsScholarship = ({ formData, setFormData, nextStep, prevStep }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { http } = AuthUser();
  const SEEF_PERSONALS = "siblings";

  const initialScholarshipState = {
    siblingsReceivingScholarship: formData.siblingsScholarship?.siblingsReceivingScholarship || "yes",
    name: formData.siblingsScholarship?.name || "",
    relationship: formData.siblingsScholarship?.relationship || "",
    university: formData.siblingsScholarship?.university || "",
  };

  const [scholarshipData, setScholarshipData] = useState(initialScholarshipState);

  useEffect(() => {
    setScholarshipData({
      siblingsReceivingScholarship: formData.siblingsScholarship?.siblingsReceivingScholarship || "yes",
      name: formData.siblingsScholarship?.name || "",
      relationship: formData.siblingsScholarship?.relationship || "",
      university: formData.siblingsScholarship?.university || "",
    });
  }, [formData.siblingsScholarship]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedScholarshipData = {
      ...scholarshipData,
      [name]: value,
    };

     // If siblingsReceivingScholarship is "no", clear related fields
     if (name === "siblingsReceivingScholarship" && value === "no") {
      updatedScholarshipData = {
        siblingsReceivingScholarship: "no",
        name: "",
        relationship: "",
        university: "",
      };
    }

    setScholarshipData(updatedScholarshipData);

    setFormData({
      ...formData,
      siblingsScholarship: updatedScholarshipData,
    });

    // Reset errors for the field being changed
    setErrors({
      ...errors,
      [name]: "",
    });

  };

  const validate = () => {
    const newErrors = {};
    if (!scholarshipData.siblingsReceivingScholarship) {
      newErrors.siblingsReceivingScholarship = "Please select an option";
    }
    if (scholarshipData.siblingsReceivingScholarship === "yes" && scholarshipData.name === "") {
      newErrors.name = "Sibling's Name is required";
    }
    if (scholarshipData.siblingsReceivingScholarship === "yes" && scholarshipData.relationship === "") {
      newErrors.relationship = "Relationship of Sibling is required";
    }
    if (scholarshipData.siblingsReceivingScholarship === "yes" && scholarshipData.university === "") {
      newErrors.university = "Name of University is required";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validate();
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);

      let requestData = {
        personal_id: formData.personal_id,
        siblingsReceivingScholarship: scholarshipData.siblingsReceivingScholarship,
      };

      if (scholarshipData.siblingsReceivingScholarship === "yes") {
        requestData = {
          ...requestData,
          name: scholarshipData.name.trim(),
          relationship: scholarshipData.relationship.trim(),
          university: scholarshipData.university.trim(),
        };
      }

      try {
        // const response = await http.post(SEEF_PERSONALS, requestData);
        // console.log("Success:", response);
        setLoading(false);
        nextStep();
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
        setErrors({
          submit: "Failed to submit data. Please try again later.",
        });
      }
    } else {
      setErrors(newErrors);
    }
  };


  return (
    <form onSubmit={handleSubmit} className="max-w-screen-md mx-auto px-4">
      <h2 className="text-2xl font-bold mb-6">Siblings Scholarship Information</h2>
      <div className="mb-6 max-w-screen-sm mx-auto">
        <div className="mb-4">
          <label className="block text-gray-700">
            Do any of your sibling(s) already avail the SEEF Scholarship?
          </label>
          <div>
            <div className="flex items-center">
              <input
                type="radio"
                id="yes"
                name="siblingsReceivingScholarship"
                value="yes"
                checked={scholarshipData.siblingsReceivingScholarship === "yes"}
                onChange={handleChange}
                className="mr-2"
              />
              <label htmlFor="yes">Yes</label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                id="no"
                name="siblingsReceivingScholarship"
                value="no"
                checked={scholarshipData.siblingsReceivingScholarship === "no"}
                onChange={handleChange}
                className="mr-2"
              />
              <label htmlFor="no">No</label>
            </div>
          </div>
          {errors.siblingsReceivingScholarship && (
            <p className="text-red-500 text-sm">{errors.siblingsReceivingScholarship}</p>
          )}
        </div>

        {scholarshipData.siblingsReceivingScholarship === "yes" && (
          <>
            <div className="mb-4">
              <label className="block text-gray-700">Sibling's Name</label>
              <input
                type="text"
                name="name"
                value={scholarshipData.name}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Relationship of Sibling</label>
              <select
                name="relationship"
                value={scholarshipData.relationship}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              >
                <option value="">Select Relationship</option>
                <option value="Brother">Brother</option>
                <option value="Sister">Sister</option>
              </select>
              {errors.relationship && (
                <p className="text-red-500 text-sm">{errors.relationship}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Name of University</label>
              <input
                type="text"
                name="university"
                value={scholarshipData.university}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-md"
              />
              {errors.university && <p className="text-red-500 text-sm">{errors.university}</p>}
            </div>
          </>
        )}
      </div>

      <div className="flex justify-between">
        <button type="button" onClick={prevStep} className="px-6 py-2 bg-gray-600 text-white rounded-md">
          Back
        </button>
        <button 
          type="submit" 
          disabled={loading} 
          className={`px-6 py-2 ${loading ? 'bg-gray-400' : 'bg-blue-600'} text-white rounded-md`}
        >
          {loading ? 'Please wait...' : 'Next'}
        </button>
      </div>
      {errors.submit && (
        <p className="text-red-500 text-sm mt-4">{errors.submit}</p>
      )}
    </form>
  );
};

export default SiblingsScholarship;
