// import React, { useState, useEffect } from 'react';
// import Header from '../../Header/Header';
// import axios from 'axios'; // Import Axios for making HTTP requests

// const MainForm = () => {
//   const [userData, setUserData] = useState({
//     firstName: '',
//     lastName: '',
//     university: '',
//     bsProgram: '',
//     photo: '', // Add photo field to state
//     totalIncome: null // Assuming totalIncome is also fetched
//   });
//   const [cnicInput, setCnicInput] = useState('');
//   const [loading, setLoading] = useState(false); // State to manage loading state
//   const [error, setError] = useState(null); // State to manage errors

//   // Function to fetch user data from API based on CNIC
//   const fetchUserData = async () => {
//     try {
//       setLoading(true); // Set loading state while fetching data

//       // Replace with your API endpoint and CNIC input value
//       const response = await axios.get(`http://localhost:8000/api/personal-data/${cnicInput}`);
//       const data = response.data; // Assuming your API returns 'data' field with user info

//       // Update state with fetched user data
//       setUserData({
//         firstName: data.firstName,
//         lastName: data.lastName,
//         university: data.academic?.universityName,
//         bsProgram: data.academic?.discipline,
//         photo: data.photo, // Set photo field from API response
//         totalIncome: data.totalIncome
//       });

//       setLoading(false); // Reset loading state
//       setError(null); // Reset error state
//     } catch (error) {
//       setLoading(false); // Reset loading state
//       setError('Error fetching user data. Please try again.'); // Set error state
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const handleInputChange = (e) => {
//     setCnicInput(e.target.value); // Update CNIC input state
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     fetchUserData(); // Call fetchUserData function on form submit
//   };

//   return (
//     <div className="min-h-screen flex flex-col items-center p-4 bg-gray-100">
//       <Header />

//       <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg mt-4">
//         <h1 className="text-center text-xl font-bold mb-5">
//           Student Information
//         </h1>

//         {/* CNIC Input Form */}
//         <form onSubmit={handleSubmit} className="mb-4">
//           <label htmlFor="cnic" className="block font-medium text-gray-700">
//             Enter CNIC:
//           </label>
//           <input
//             type="text"
//             id="cnic"
//             name="cnic"
//             value={cnicInput}
//             onChange={handleInputChange}
//             className="border border-gray-300 rounded-lg px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-300 w-full"
//             required
//           />
//           <button
//             type="submit"
//             className="mt-3 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
//           >
//             Get Data
//           </button>
//         </form>

//         {/* Display User Data */}
//         {loading && <p className="text-center">Loading...</p>}
//         {error && <p className="text-center text-red-500">{error}</p>}
//         {!loading && !error && (
//           <div>
//             <div className="mb-4">
//               <strong>Name:</strong> {userData.firstName} {userData.lastName}
//             </div>

//             <div className="mb-4">
//               <strong>Photo:</strong>
//               {userData.photo ? (
//                 <img
//                   src={`http://localhost:8000/storage/${userData.photo}`} // Adjust the path according to your API response
//                   alt="User Photo"
//                   className="border border-gray-300 rounded-lg"
//                   style={{ width: '150px', height: '200px', objectFit: 'cover' }}
//                 />
//               ) : (
//                 <div
//                   className="border border-gray-300"
//                   style={{
//                     width: '150px',
//                     height: '200px',
//                     backgroundColor: 'lightgray'
//                   }}
//                 >
//                   {/* Placeholder for photo */}
//                   Photo Frame
//                 </div>
//               )}
//             </div>

//             <div className="mb-4">
//               <strong>University Name:</strong> {userData.university}
//             </div>

//             <div className="mb-4">
//               <strong>BS Program:</strong> {userData.bsProgram}
//             </div>

//             <div className="mb-4">
//               <strong>Total Income:</strong> {userData.totalIncome || 'Not available'}
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default MainForm;


import React, { useState } from 'react';
import Header from '../../Header/Header';
import axios from 'axios';
import { jsPDF } from 'jspdf';

const MainForm = () => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    university: '',
    bsProgram: '',
    photo: '',
    totalIncome: null
  });
  const [cnicInput, setCnicInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUserData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`http://localhost:8000/api/personal-data/${cnicInput}`);
      const data = response.data;

      setUserData({
        firstName: data.firstName,
        lastName: data.lastName,
        university: data.academic?.universityName,
        bsProgram: data.academic?.discipline,
        photo: data.photo,
        totalIncome: data.totalIncome
      });

      setLoading(false);
      setError(null);
    } catch (error) {
      setLoading(false);
      setError('Error fetching user data. Please try again.');
      console.error('Error fetching user data:', error);
    }
  };

  const handleInputChange = (e) => {
    setCnicInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchUserData();
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text('Student Information', 20, 20);

    doc.text(`Name: ${userData.firstName} ${userData.lastName}`, 20, 30);
    doc.text(`University Name: ${userData.university}`, 20, 40);
    doc.text(`BS Program: ${userData.bsProgram}`, 20, 50);
    doc.text(`Total Income: ${userData.totalIncome || 'Not available'}`, 20, 60);

    if (userData.photo) {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Ensure cross-origin handling
      img.src = `http://localhost:8000/storage/${userData.photo}`;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        const dataURL = canvas.toDataURL('image/jpeg');
        doc.addImage(dataURL, 'JPEG', 20, 70, 60, 80);
        doc.save('student_information.pdf');
      };
    } else {
      doc.save('student_information.pdf');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center p-4 bg-gray-100">
      <Header />

      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-lg mt-4">
        <h1 className="text-center text-xl font-bold mb-5">
          Student Information
        </h1>

        <form onSubmit={handleSubmit} className="mb-4">
          <label htmlFor="cnic" className="block font-medium text-gray-700">
            Enter CNIC:
          </label>
          <input
            type="text"
            id="cnic"
            name="cnic"
            value={cnicInput}
            onChange={handleInputChange}
            className="border border-gray-300 rounded-lg px-3 py-2 mt-1 focus:outline-none focus:ring focus:border-blue-300 w-full"
            required
          />
          <button
            type="submit"
            className="mt-3 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          >
            Get Data
          </button>
        </form>

        {loading && <p className="text-center">Loading...</p>}
        {error && <p className="text-center text-red-500">{error}</p>}
        {!loading && !error && (
          <div>
            <div className="mb-4">
              <strong>Name:</strong> {userData.firstName} {userData.lastName}
            </div>

            <div className="mb-4">
              <strong>Photo:</strong>
              {userData.photo ? (
                <img
                  src={`http://localhost:8000/storage/${userData.photo}`}
                  alt="User Photo"
                  className="border border-gray-300 rounded-lg"
                  style={{ width: '150px', height: '200px', objectFit: 'cover' }}
                />
              ) : (
                <div
                  className="border border-gray-300"
                  style={{
                    width: '150px',
                    height: '200px',
                    backgroundColor: 'lightgray'
                  }}
                >
                  Photo Frame
                </div>
              )}
            </div>

            <div className="mb-4">
              <strong>University Name:</strong> {userData.university}
            </div>

            <div className="mb-4">
              <strong>BS Program:</strong> {userData.bsProgram}
            </div>

            <div className="mb-4">
              <strong>Total Income:</strong> {userData.totalIncome || 'Not available'}
            </div>

            <button
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              onClick={handleDownloadPDF}
            >
              Download PDF
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainForm;

